<template>

    <div class="box-content-lg">
        <div class="box-wrap mb-3">
            <div class="title-text text-blue"><strong>INTERNAL ACCESS ONLY</strong></div>
            <div class="box-border-blue">
                <div class="text-blue title-mb2"><strong>{{PriseCharge.title}}</strong></div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                            <div class="box-padding">
                                <div class="box-check box-check-text" v-for="(item, key) in PriseCharge.prise_en_charge.items.slice(0,3)" :key="key">
                                    <label> 
                                        <input type="checkbox" :value="item.value" v-model="PriseCharge.prise_en_charge.data" name="prise_en_charge">
                                        <span class="check-recherche"></span>
                                        <div class="txt-check text-gray">
                                            {{item.display}}
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="box-padding">
                            <div class="box-check box-check-text" v-for="(item, key) in PriseCharge.prise_en_charge.items.slice(3,6)" :key="key">
                                <label>
                                    <input type="checkbox" :value="item.value" v-model="PriseCharge.prise_en_charge.data" name="prise_en_charge">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-wrap mb-3">
            <div class="box-border-white box-border-white-sm box-shadow">
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-12 text-blue box-inline text-14"><strong>DATE</strong></div>
                    <div class="col-lg-9 col-md-9 col-12">
                        <div class="text-blue">
                            <div class="box-input-circle"  style="margin-right: 40px;">
                                <datepicker 
                                    v-model="PriseCharge.debut.data" 
                                    :locale="language"
                                    inputFormat="MM/yyyy" 
                                    minimumView="month"
                                    class="form-control" 
                                    :placeholder="PriseCharge.debut.title"
                                    :clearable="true" autocomplete="off"
                                    >  
                                    <template v-slot:clear>
                                        <div class="date_clearable" @click="PriseCharge.debut.data = null"><em>x</em></div>
                                    </template>
                                </datepicker>
                            </div>
                            <div class="box-input-circle">
                                <datepicker 
                                    v-model="PriseCharge.fin.data" 
                                    :locale="language"
                                    inputFormat="MM/yyyy" 
                                    minimumView="month"
                                    class="form-control" 
                                    :placeholder="PriseCharge.fin.title"
                                    :clearable="true" autocomplete="off"
                                    >  
                                    <template v-slot:clear>
                                        <div class="date_clearable" @click="PriseCharge.fin.data = null"><em>x</em></div>
                                    </template>
                                </datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="box-wrap mb-3 relative">
            <div class="box-border-blue">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="box-padding">
                            <div class="box-check box-check-text">
                                <label> 
                                    <input type="checkbox" :value="PriseCharge.liste_ss.items[0].value" v-model="PriseCharge.liste_ss.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{PriseCharge.liste_ss.title}}
                                    </div>
                                </label>
                            </div>
                            <div class="box-check box-check-text">
                                <label> 
                                    <input type="checkbox" :value="PriseCharge.liste_coll.items[0].value" v-model="PriseCharge.liste_coll.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{PriseCharge.liste_coll.title}}
                                    </div>
                                </label>
                            </div>
                            <div class="box-check box-check-text">
                                <label> 
                                    <input type="checkbox" :value="PriseCharge.prise_en_charge_precoce.items[0].value" v-model="PriseCharge.prise_en_charge_precoce.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{PriseCharge.prise_en_charge_precoce.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                   <div class="col-lg-4 col-md-4 col-12">
                        <div class="box-padding">
                        <div class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="PriseCharge.liste_retrocession.items[0].value" v-model="PriseCharge.liste_retrocession.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{PriseCharge.liste_retrocession.title}}
                                </div>
                            </label>
                        </div>
                        <div class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="PriseCharge.liste_en_sus_t2a.items[0].value" v-model="PriseCharge.liste_en_sus_t2a.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{PriseCharge.liste_en_sus_t2a.title}}
                                </div>
                            </label>
                        </div>
                        </div>
                    </div>
                     
                </div>
            </div>
            <div class="area-overlay" v-show="disabled_PriseCharge" style="z-index:1;"></div>
        </div>
    </div>

</template>

<script> 
import Datepicker from 'vue3-datepicker'
import { enGB, fr, de } from 'date-fns/locale'
export default {
  name: 'PriseCharge',
  components: { 
      Datepicker
  },
  computed : {
      language(){
          return this.$i18n.locale !== 'en' ? this.languages[this.$i18n.locale] : this.languages[enGB]
      }
  },
  data: function(){
       return {
            languages : {enGB, fr, de },
            disabled_PriseCharge : true,
            PriseCharge : {
                'title' : this.$t('advance.prise_en_charge.title'), 
                prise_en_charge : {
                    title : this.$t('Prise en charge'),
                    items: [
                            {'display' : this.$t('Prise en charge'), 'value' : 'Prise en charge'},
                            {'display' : this.$t('En cours'), 'value' : 'En cours'},
                            {'display': this.$t('Non pris en charge'), 'value' : 'Non pris en charge'},
                            {'display': this.$t('Radiation'), 'value' : 'Radiation'},
                            // {'display': this.$t('Prise en charge précoce'), 'value' : 'Prise en charge précoce'},
                            {'display': this.$t('Prise en charge dérogatoire'), 'value' : 'Prise en charge dérogatoire'}
                    ],
                    data : [],
                },
                debut : {
                    title : this.$t('date_start'),
                    data : null,
                },
                fin : {
                    title : this.$t('date_end'),
                    data : null,
                },
                liste_ss : {
                    title : 'Liste SS',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : [],
                },
                liste_coll : {
                    title : 'Liste COLL',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : [],
                },
                liste_retrocession : {
                    title : 'Liste Retrocession',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : [],
                },
                liste_en_sus_t2a : {
                    title : 'Liste en sus T2A',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : [],
                },
                prise_en_charge_precoce : {
                    title : this.$t('Prise en charge précoce'),
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : [],
                },
            },
            lang : localStorage.getItem('language')
      }
  },
  methods :{
      check_prise_en_charge(){
          if(this.PriseCharge.prise_en_charge.data.length == 0){
            this.disabled_PriseCharge = true
            if(this.PriseCharge.liste_ss.data.length > 0 ||
                this.PriseCharge.liste_coll.data.length > 0 ||
                this.PriseCharge.liste_retrocession.data.length > 0 ||
                this.PriseCharge.liste_en_sus_t2a.data.length > 0 ||
                this.PriseCharge.prise_en_charge_precoce.data.length > 0)
            {
                // alert('Veuillez sélectionner un champ parmi la liste "Prise en charge".')
                this.PriseCharge.liste_ss.data = []
                this.PriseCharge.liste_coll.data = []
                this.PriseCharge.liste_retrocession.data = []
                this.PriseCharge.liste_en_sus_t2a.data = []
                this.PriseCharge.prise_en_charge_precoce.data = []
            }

          }else{
              this.disabled_PriseCharge = false
          }
      }
  },
  updated() {
        this.$emit("onReturn", { key: "Prise_en_Charge", value: this.PriseCharge });

        this.check_prise_en_charge()
    },
    mounted() {
        this.$emit("onReturn", { key: "Prise_en_Charge", value: this.PriseCharge });
    },
}
</script>

