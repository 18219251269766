<template>
<div>
    <Header headerType="advance"></Header>

    <!-- content main -->

    <div class="container-fluid search_page">
        <form @submit.prevent="handleSubmit">
        <div class="row">
            <div class="header-main-top">
                <div class="row">
                    <div class="col-lg-3 col-md-12 col-12 px-0">
                        <div class="box-input">
                            <autocomplete :items="autocomplete_name" inputname="name" @onAutocomplete="onAutocomplete" methodfor="advance"  :clear="datas.name.clear" :placeholder="datas.name.items.placeholder" :classinputname="'form-cs'" style_autocomplete="top: 35px;"/>    
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 px-0">
                        <div class="box-input">
                            <autocomplete class="input-group" :items="autocomplete_therapeutic" inputname="therapeutic" @onAutocomplete="onAutocomplete" methodfor="advance"  :clear="datas.therapeutic.clear" :placeholder="datas.therapeutic.items.placeholder" style_autocomplete="top: 35px;"> 
                                <div class="input-group-append">
                                    <span class="input-group-text bt-radius" @click="popupTherapeutic" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#modal-therapeutic"><em class="fa fa-plus-circle"></em> </span>
                                </div>
                            </autocomplete>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-12 px-0">
                        <div class="box-input">
                            <autocomplete :items="autocomplete_atc" inputname="atc" @onAutocomplete="onAutocomplete" methodfor="advance"  :clear="datas.atc.clear" :placeholder="datas.atc.items.placeholder" :classinputname="'form-cs'" style_autocomplete="top: 35px;">
                                <!-- <div class="input-group-append">
                                    <span class="input-group-text bt-radius" @click="popupATC" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#modal-atc"><em class="fa fa-plus-circle"></em> </span>
                                </div> -->
                            </autocomplete>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 col-12 px-0">
                        <div class="box-input">
                            <autocomplete :items="autocomplete_firm" inputname="firm" @onAutocomplete="onAutocomplete" methodfor="advance"  :clear="datas.firm.clear" :classinputname="'form-cs'" :placeholder="datas.firm.items.placeholder" style_autocomplete="top: 35px;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="wrapper">

            <div class="header-main-button">
                <a @click="selectTab('AtuRtu')"> <div :class="'btn-border-blue bt-lg ' + (tabs.AtuRtu ? 'btn-blue' : '')">Accès précoce / accès compassionnel</div></a>
                <a @click="selectTab('TransparenceTC')"> <div :class="'btn-border-blue bt-lg ' + (tabs.TransparenceTC ? 'btn-blue' : '')">TRANSPARENCE TC</div></a>
                <a @click="selectTab('Ceesp')"> <div :class="'btn-border-blue bt-lg ' + (tabs.Ceesp ? 'btn-blue' : '')">CEESP</div></a>
                <a @click="selectTab('Prix')"> <div :class="'btn-border-blue bt-lg ' + (tabs.Prix ? 'btn-blue' : '')">PRIX</div></a>
                <a @click="selectTab('PriseCharge')" v-if="isAdmin()"> <div :class="'btn-border-blue bt-lg ' + (tabs.PriseCharge ? 'btn-blue' : '')">PRISE EN CHARGE</div></a>
            </div>
            <div >
                <!-- {{temp}} -->
                <div class="wrapper-contents">
                        <TabAtuRtu v-show="tabs.AtuRtu" @onReturn="onReturn"/>
                        <TabTransparenceTC v-show="tabs.TransparenceTC" @onReturn="onReturn" />
                        <TabCeesp v-show="tabs.Ceesp" @onReturn="onReturn" />
                        <TabPrix v-show="tabs.Prix" @onReturn="onReturn"/>
                        <TabPriseCharge v-show="tabs.PriseCharge" @onReturn="onReturn" />
                    <!-- box-info -->
                    <infoSearch :datas="datas" @onReturn="onReturn" />
                </div>
            </div>
        </div>

        </form>
    </div>
        <ModalSelection :title="datas.therapeutic.title" :list="autocomplete_therapeutic" name="therapeutic" @onModal="onModal" />        
        <!-- <ModalSelection :title="datas.atc.title" :list="autocomplete_atc" name="atc" @onModal="onModal" /> -->

        <Footer />
    </div>
        

</template>

<style>

</style>

<script>
import Header from '@/components/Layout/Header.vue' 
import Footer from '@/components/Layout/Footer.vue';
import Autocomplete from '@/components/elements/autocomplete.vue' 
import ModalSelection from '@/components/elements/modal_selection.vue'
import TabAtuRtu from '@/components/advance/atu-rtu.vue'
import TabCeesp from '@/components/advance/ceesp.vue'
import TabTransparenceTC from '@/components/advance/transparence-tc.vue'
import TabPrix from '@/components/advance/prix.vue'
import TabPriseCharge from '@/components/advance/prise-charge.vue'
import InfoSearch from '@/components/advance/info-search.vue';
import { isAdmin, isValidDate, base64url_encode } from '@/utils';
import moment from 'moment'
import { Base64 } from 'js-base64'

export default {
    name: 'advance',
    components: {
        Header,
        Footer,
        Autocomplete,
        ModalSelection,
        InfoSearch,
        TabAtuRtu,
        TabTransparenceTC,
        TabCeesp,
        TabPrix,
        TabPriseCharge
    },
    data:  function(){
        return {
            testm : [],
            tabs : {
                AtuRtu : false,
                TransparenceTC : true,
                Ceesp : false,
                Prix : false,
                PriseCharge : false,
            },
            datas : {
                'name' : {
                    'title' : this.$t('advance.name'),                    
                    'clear' : true,
                    'items' : {data : [], data_exclude : [], placeholder : this.$t('advance.name')}
                },
                'therapeutic' : {
                    'title' : this.$t('advance.therapeutic'),                    
                    'clear' : true,
                    'items' : {data : [], data_exclude : [], placeholder : this.$t('advance.therapeutic')}
                },
                'atc' : {
                    'title' : "ATC",                    
                    'clear' : true,
                    'items' : {data : [], data_exclude : [], placeholder : "ATC"}
                },
                'firm' : {
                    'title' : this.$t('advance.firm'),                    
                    'clear' : true,
                    'items' : {data : [], data_exclude : [], placeholder : this.$t('advance.firm')}
                },
                'Atu' : [],
                'Rtu' : [],
                'Transparence' : [],
                'Ceesp' : [],
                'Prix' : [],
                'Prise_en_Charge' : [],
            }, 
            'temp' : "",
            'modal' : {
                'therapeutic' : {'open' : false, 'title' : this.$t("simple.therapeutic"), 'result' : []},
                // 'atc' : {'open' : false, 'title' : "ATC", 'result' : []},
            }
            
            
        }
    },
    mounted(){ 
        this.loadAutocomplete() 
 
        if(this.$route.params.base64Search) {
            this.fillOutFields(Base64.decode(this.$route.params.base64Search))
        }  
    },
    methods: {
        loadAutocomplete(){ 
            const keyLoadAutoComplete = []
            if(this.$store.getters['autocomplete/name'].length === 0){
                keyLoadAutoComplete.push('name')
            }
            if(this.$store.getters['autocomplete/therapeutic'].length === 0){
                keyLoadAutoComplete.push('therapeutic')
            }
            if(this.$store.getters['autocomplete/atc'].length === 0){
                keyLoadAutoComplete.push('atc')
            }
            if(this.$store.getters['autocomplete/firm'].length === 0){
                keyLoadAutoComplete.push('firm')
            }
            if(keyLoadAutoComplete.length > 0){
                this.$store.dispatch("autocomplete/loadAutocomplete", keyLoadAutoComplete)
            }
        }, 
        selectTab(tab){
            for(let d in this.tabs){
                this.tabs[d] = false; 
            }
            this.tabs[tab] = true;
        },
        popupTherapeutic(){
            this.modal.therapeutic.open = true
        },
        isAdmin,
        // popupATC(){
        //     this.modal.atc.open = true
        // },
        onReturn(param){
            this.datas[param.key] = param.value;  
        },   
        onAutocomplete(param){ 
            console.log('onAutocomplete')          
            console.log(param)
            if(this.datas[param.key].items.data.indexOf(param.value) === -1){
                this.datas[param.key].items.data.push(param.value);
                // this.modal[param.key].result.push(param.value);
            }
        },
        onModal(param){
            console.log('onModal')
            console.log(param)
            for(let i in param.value){
                if(!this.datas[param.key].items.data.includes(param.value[i])){
                    this.modal[param.key].result.push(param.value[i]);
                    this.datas[param.key].items.data.push(param.value[i]);
                }
            }
            this.modal[param.key] = param.modal
        },
        check_include_notin_exclude(include, obj){
            if(obj.data_exclude !== undefined){
                if(Array.isArray(include) && include.length > 0){                        
                    return include.filter(x => !obj.data_exclude.includes(x));                                   
                }else if(typeof include == 'string' && include !== '' && obj.data_exclude === ''){
                    return include;   
                }else if((Array.isArray(include) && include.length === 0) || (typeof include == 'string' && include === '')){
                    return include;   
                }
            }
            return include;            
        },
        isValidDate,
        handleSubmit(){ 
            let strResultForm = ""
            let strResultFormExclude = ""
            const public_search = ['name', 'therapeutic', 'atc', 'firm']

            if(this.datas.Prise_en_Charge.prise_en_charge && this.datas.Prise_en_Charge.prise_en_charge.length == 0){
                if(this.datas.Prise_en_Charge.liste_ss.length > 0 ||
                    this.datas.Prise_en_Charge.liste_coll.length > 0 ||
                    this.datas.Prise_en_Charge.liste_retrocession.length > 0 ||
                    this.datas.Prise_en_Charge.liste_en_sus_t2a.length > 0)
                {
                    alert('Veuillez sélectionner un champ parmi la liste "Prise en charge".')
                }
            }
             
            //find include keyword
            for (const [title, datas] of Object.entries(this.datas)) {                
                 if(public_search.includes(title)){
                    const include_notin_exclude = this.check_include_notin_exclude(datas.items.data, datas.items);
                    if(Array.isArray(include_notin_exclude) && include_notin_exclude.length)
                        strResultForm += title + "=" + include_notin_exclude.join('#') + "|"
                    else if(typeof include_notin_exclude == 'string' && include_notin_exclude.length)
                        strResultForm += title + "=" + include_notin_exclude + "|"  
                                 
                }else{
                    //for(let sub_titile in datas){
                    for (const [sub_titile, item] of Object.entries(datas)) {     
                        const include_notin_exclude = this.check_include_notin_exclude(item.data, item);                           
                        if(Array.isArray(include_notin_exclude) && include_notin_exclude.length){
                            strResultForm += title + "@" + sub_titile + "=" + include_notin_exclude.join('#') + "|"                            
                        }else if(typeof include_notin_exclude == 'string' && include_notin_exclude.length){
                            strResultForm += title + "@" + sub_titile + "=" + include_notin_exclude + "|"                             
                        }else if(this.isValidDate(include_notin_exclude)){
                            strResultForm += title + "@" + sub_titile + "=" + (sub_titile === "DateJO" ? moment(include_notin_exclude).format("DD-MM-YYYY") : moment(include_notin_exclude).format("MM/YYYY") ) + "|"   
                        }
                    }
                }
            } 

            //find exclude keyword if(obj.data_exclude !== undefined){
            for (const [title, datas] of Object.entries(this.datas)) {                
                 if(public_search.includes(title)){
                    if(datas.items.data_exclude !== undefined){
                        if(Array.isArray(datas.items.data_exclude) && datas.items.data_exclude.length > 0)
                            strResultFormExclude += title + "=" + datas.items.data_exclude.join('#') + "|"
                        else if(typeof datas.items.data_exclude == 'string' && datas.items.data_exclude !== '')
                            strResultFormExclude += title + "=" + datas.items.data_exclude + "|"
                    }
                }else{
                    for (const [sub_titile, item] of Object.entries(datas)) {                          
                        if(item.data_exclude !== undefined && Array.isArray(item.data_exclude) && item.data_exclude.length > 0){
                            strResultFormExclude += title + "@" + sub_titile + "=" + item.data_exclude.join('#') + "|"                            
                        }else if(item.data_exclude !== undefined && typeof item.data_exclude == 'string' && item.data_exclude !== ''){
                            strResultFormExclude += title + "@" + sub_titile + "=" + item.data_exclude + "|"                             
                        } 
                    }
                }
            }

            if(strResultFormExclude.length){
                strResultForm += "exclude_keyword" + strResultFormExclude;
            }
           
            if(strResultForm === ""){
                alert(this.$t('The field cannot be empty'))
                return false
            }
            console.log(strResultForm)
             

            let atu, rtu, transparence, ceesp, prix, prise_en_charge, cpc; 
            strResultForm.indexOf("Acces_precoce@") > -1 || strResultForm.indexOf("Acces_compassionnel@compassionnel=ATU nominative") > -1 || strResultForm.indexOf("Acces_compassionnel@compassionnel=AAC") > -1 ? atu = true : atu = false
            strResultForm.indexOf("Acces_compassionnel@compassionnel=Rtu") > -1 ? rtu = true : rtu = false
            strResultForm.indexOf("Acces_compassionnel@compassionnel=CPC") > -1 ? cpc = true : cpc = false
            strResultForm.indexOf("Transparence@") > -1 ? transparence = true : transparence = false
            strResultForm.indexOf("Ceesp@") > -1 ? ceesp = true : ceesp = false
            strResultForm.indexOf("Prix@") > -1 ? prix = true : prix = false
            strResultForm.indexOf("Prise_en_Charge@") > -1 ? prise_en_charge = true : prise_en_charge = false
                        
                            
            let url = "";
            let url_param = ""
            if(prix)
                url = "advanced_result_prix";
            else if(rtu || cpc && (!transparence && !atu && !prise_en_charge && !ceesp))
                url = "advanced_result_rtu"; 
            else if(prise_en_charge && !rtu && !transparence && !atu && !ceesp)
                url = "advanced_result_prise_en_charge";
            else if(atu && (!rtu && !transparence && !ceesp && !prise_en_charge) )
                url = "advanced_result_atu_postatu"; 
            else if(ceesp && !rtu && !transparence && !atu && !prise_en_charge)
                url = "advanced_result_ceesp";
            else if(ceesp && transparence && !rtu && !atu && !prise_en_charge)
                url = "advanced_result_trs_ceesp";
            else if( ceesp && (transparence || atu || rtu) && (!prise_en_charge) ){
                url = "result";  
                url_param = "/advanced_trs_ceesp" 
            }else
                url = "advanced_result";
            
            if(strResultForm.indexOf("Prix@Generique") > -1){
                url_param = "?options=generique";
            }
            this.$router.push(`/${url}/${base64url_encode(strResultForm)}${url_param}`) // eslint-disable-line
                
        },
        /**
         * Fill the datas fields (with previous keywords' search) when user click on 'update my search" on result's page
         * @param string keywords 
         */
        fillOutFields(keywords) {
            let keywordsArr = keywords.split('|')
            let tabs = []
            for( var i = 0; i < keywordsArr.length; i++){              
                if (    keywordsArr[i].includes('therapeutic') || 
                        keywordsArr[i].includes('name') || 
                        keywordsArr[i].includes('atc') || 
                        keywordsArr[i].includes('firm')
                ) { 
                    let keyword = keywordsArr[i].split('=')
                    let type = keyword[0]
                    let values = keyword[1].split('#')
                    values.forEach(v => {
                        this.datas[type].items.data.push(v);
                    })
                } else if(keywordsArr[i] != "") {
                    let keyword = keywordsArr[i].split('@')
                    let agency = keyword[0]
                    tabs.push(agency)

                    let agency_types = keyword[1].split('=')
                    let type = agency_types[0]
                    let values = agency_types[1].split('#')
                    values.forEach(v => {
                        if(Array.isArray(this.datas[agency][type].data))
                            this.datas[agency][type].data.push(v)
                        else{
                            if(type === 'date_start' || type === 'date_end' || type.includes('_Debut') || type.includes('_Fin')) {
                                let date = v.split('/')
                                this.datas[agency][type].data = new Date(date[1] + '-' + date[0])
                            } else
                                this.datas[agency][type].data = v
                        }
                    })
                }
            }

            //tabs selection depends on previous search
            if(tabs.includes('Transparence'))
                this.selectTab('TransparenceTC')
            else if(tabs.includes('Ceesp'))
                this.selectTab('Ceesp')
            else if(tabs.includes('Acces_precoce'))
                this.selectTab('AtuRtu')
            else if(tabs.includes('Prix'))
                this.selectTab('Prix')
            else if(tabs.includes('Prise_en_Charge'))
                this.selectTab('PriseCharge')
        }
    },
    computed : {
        autocomplete_name () {  
            return this.$store.getters['autocomplete/name']            
        },
        autocomplete_therapeutic : function(){
             return this.$store.getters['autocomplete/therapeutic']
        },
        autocomplete_atc : function(){            
            return this.$store.getters['autocomplete/atc']
        },
        autocomplete_firm : function(){
            return this.$store.getters['autocomplete/firm']
        } 

    }
}
</script>

<style scoped>
a{cursor: pointer;}
@media screen and (max-width: 991px) {
    .wrapper-contents {
        flex-direction: column !important;
    }
    .box-info {
        width: 100% !important;
        margin-left: 0px !important;
    }
}
</style>