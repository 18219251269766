<template>
 
    <div class="box-content-lg">
        <div class="box-wrap">
            <div class="title-text text-blue"> {{$t('Accès précoce')}}</div>
            
            <div class="box-border-blue">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12 border-right-blue">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>{{precoce.MotifATU.title}} :</strong></div>
                            <div class="box-check box-check-text" v-for="(item, key) in precoce.MotifATU.items" :key="key">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="precoce.MotifATU.data" name="MotifATU">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>{{precoce.TypeAAP.title}} :</strong></div>
                            <div class="box-check box-check-text " v-for="(item, key) in precoce.TypeAAP.items" :key="key">
                                <label>
                                    <input type="checkbox" :value="item.value" v-model="precoce.TypeAAP.data" name="TypeAAP">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12" v-if="0">
                        <div class="box-padding">
                           
                            <div class="text-blue">
                                <div class="box-inline"><strong>{{precoce.DateoctroiATU.title}} : </strong></div>
                                <div class="box-input-circle">
                                    <datepicker 
                                        v-model="precoce.DateoctroiATU.data" 
                                        :locale="language"
                                        inputFormat="MM/yyyy" 
                                        minimumView="month"
                                        class="form-control" 
                                        :placeholder="precoce.DateoctroiATU.placeholder"
                                        :clearable="true" name="DateoctroiATU" autocomplete="off"
                                        >  
                                        <template v-slot:clear>
                                            <div class="date_clearable" @click="precoce.DateoctroiATU.data = null"><em>x</em></div>
                                        </template>
                                    </datepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="box-border-blue">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12 border-right-blue">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>{{precoce.AvisCT.title}} :</strong></div>
                            <div class="box-check box-check-text " v-for="(item, key) in precoce.AvisCT.items" :key="key">
                                <label>
                                    <input type="checkbox" :value="item.value" v-model="precoce.AvisCT.data" name="AvisCT">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>{{precoce.DecisionCollege.title}} :</strong></div>
                            <div class="box-check box-check-text " v-for="(item, key) in precoce.DecisionCollege.items" :key="key">
                                <label>
                                    <input type="checkbox" :value="item.value" v-model="precoce.DecisionCollege.data" name="DecisionCollege">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-wrap">
            <div class="title-text text-blue">{{$t('Accès compassionnel')}}</div>

            <div class="box-border-blue">
                <div class="row">
                    <div class="col-12">
                        <div class="box-padding">
                            <template v-for="(item, key) in compassionnel.compassionnel.items" :key="'compassionnel_'+key" >
                                <div class="box-check box-check-text" >
                                    <label>
                                        <input type="radio" name="compassionnel_Type" id="compassionnel_Type" :checked="compassionnel.compassionnel.data===item.value" @click="check_uncheck(item.value)">
                                        <span class="check-recherche compassionnel"></span>
                                        <div class="txt-check text-gray">
                                            {{item.display}}
                                        </div>
                                    </label>
                                </div>
                            </template>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
 
<!--&lt;!&ndash; end content main &ndash;&gt;-->
</template>


<script>
import Datepicker from 'vue3-datepicker'
import { enGB, fr, de } from 'date-fns/locale'

export default {
  name: 'AtuRtu',
  components: {
       Datepicker
  },
  computed : {
      language(){
          return this.$i18n.locale !== 'en' ? this.languages[this.$i18n.locale] : this.languages[enGB]
      }
  },
  data:  function(){
      return {
          languages : {enGB, fr, de },
          precoce :  {
                'title' : this.$t('Accès précoce'),
                MotifATU : {
                    title : this.$t('Motif'),
                    items : [
                        {'display': this.$t('atu_motif.ATU cohorte'), 'value' : 'ATU cohorte'},
                        {'display': this.$t('atu_motif.AAP'), 'value' : 'AAP'},
                    ],
                    data : [],
                    data_exclude : []
                },
                 DateoctroiATU : {
                    title : this.$t('advance.atu_rtu.DateoctroiATU'),
                    titleInfo : this.$t('advance.atu_rtu.DateoctroiATU'),
                    placeholder : 'Octroi',
                    data : null
                },
                TypeAAP : {
                    title : this.$t("Type d'AAP"),
                    items : [
                        {'display': this.$t('Pré-AMM'), 'value' : 'Pré-AMM'},
                        {'display': this.$t('Post-AMM'), 'value' : 'Post-AMM'}
                    ],
                    data : [],
                    data_exclude : []
                },
                AvisCT : {
                    title : this.$t('Avis CT') + ' ('+this.$t('AAP')+')',
                    items : [
                        {'display' : this.$t('Favorable'), 'value' : 'Positive'},
                        {'display': this.$t('Défavorable'), 'value' : 'Negative'}
                    ],
                    data : [],
                    data_exclude : []
                },
                DecisionCollege : {
                    title : this.$t('Décision du collège') + ' ('+this.$t('AAP')+')',
                    items : [
                        {'display' : this.$t('Favorable'), 'value' : 'Positive'},
                        {'display': this.$t('Défavorable'), 'value' : 'Negative'}
                    ],
                    data : [],
                    data_exclude : []
                },
            },
            compassionnel:{
                title : this.$t('Accès compassionnel'),
                compassionnel : {
                    title : this.$t('Accès compassionnel'),
                    items : [
                        {value : 'ATU nominative', display : this.$t('atu_motif.ATU nominative') }, 
                        {value : "Rtu", display : 'RTU'},
                        {value : "AAC", display : this.$t('atu_motif.AAC')},
                        {value : "CPC", display : 'CPC'},
                    ],
                    data : null,
                }                    
            },
            lang : localStorage.getItem('language')
      }
  },
  methods : {
      check_uncheck(value){
          if(value === this.compassionnel.compassionnel.data){
              this.compassionnel.compassionnel.data = null
          } else {
              this.compassionnel.compassionnel.data = value
          }
      },
      handleEvent() {
        this.$emit("onReturn", { key: "Acces_precoce", value: this.precoce });
        this.$emit("onReturn", { key: "Acces_compassionnel", value: this.compassionnel });
      },
  },
  
  mounted() {
    this.handleEvent() 
  },
  updated() {
        this.handleEvent() 
    },

}
</script>





