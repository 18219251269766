<template>

    <div class="box-content-lg">
        <div class="box-wrap">
            <div>
                <div class="title-text text-blue"><strong>DECISION DU COLLEGE DE LA HAS</strong></div>
                <div class="sub-text">
                    <div class="box-check box-check-text">
                        <label>
                            <input type="checkbox" :value="ceesp.DecisionduCollegedelaHAS.items[0].value" v-model="ceesp.DecisionduCollegedelaHAS.data">
                            <span class="check-recherche"></span>
                            <div class="txt-check text-17">
                                {{ceesp.DecisionduCollegedelaHAS.title}}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="box-border-blue">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-12 border-right-blue">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>{{ceesp.Impactsignificatif.title}} :</strong></div>
                            <div class="box-check box-check-text" v-for="(item, key) in ceesp.Impactsignificatif.items" :key="'Impactsignificatif_'+key">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="ceesp.Impactsignificatif.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12 border-right-blue">
                         <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>{{ceesp.Conclusion.title}} :</strong></div>
                            <div class="box-check box-check-text" v-for="(item, key) in ceesp.Conclusion.items" :key="'Conclusion_'+key">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="ceesp.Conclusion.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                            <div class="box-check box-check-text" v-for="(item, key) in ceesp.BaissePrixEngagee.items" :key="'BaissePrixEngagee_'+key">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="ceesp.BaissePrixEngagee.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>Date :</strong></div>
                            <div class="row">
                                <div class="col-12 col-lg-12 col-md-12 mb-2">
                                    <div class="box-input-circle">
                                        <datepicker 
                                            v-model="ceesp.DecisionduCollegedelaHAS_Debut.data" 
                                            :locale="language"
                                            inputFormat="MM/yyyy" 
                                            minimumView="month"
                                            class="form-control" 
                                            :placeholder="ceesp.DecisionduCollegedelaHAS_Debut.title"
                                            :clearable="true" name="DecisionduCollegedelaHAS_Debut" autocomplete="off"
                                            >  
                                            <template v-slot:clear>
                                                <div class="date_clearable" @click="ceesp.DecisionduCollegedelaHAS_Debut.data = null"><em>x</em></div>
                                            </template>
                                        </datepicker>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-12 col-md-12 mb-2">
                                    <div class="box-input-circle">
                                        <datepicker 
                                            v-model="ceesp.DecisionduCollegedelaHAS_Fin.data" 
                                            :locale="language"
                                            inputFormat="MM/yyyy" 
                                            minimumView="month"
                                            class="form-control" 
                                            :placeholder="ceesp.DecisionduCollegedelaHAS_Fin.title"
                                            :clearable="true" name="DecisionduCollegedelaHAS_Fin" autocomplete="off"
                                            >  
                                            <template v-slot:clear>
                                                <div class="date_clearable" @click="ceesp.DecisionduCollegedelaHAS_Fin.data = null"><em>x</em></div>
                                            </template>
                                        </datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-wrap">
            <div class="title-text text-blue">AVIS D’EFFICIENCE</div>
            <div class="sub-text">
                <div class="box-check box-check-text">
                <label>
                    <input type="checkbox" :value="ceesp.Avis_defficience.items[0].value" v-model="ceesp.Avis_defficience.data">
                    <span class="check-recherche"></span>
                    <div class="txt-check text-17">
                        {{ceesp.Avis_defficience.title}}
                    </div>
                </label>
            </div>

            <div class="box-border-blue">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-12 border-right-blue">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>{{ceesp.Appreciation.title}} :</strong></div>
                            <div class="box-check box-check-text" v-for="(item, key) in ceesp.Appreciation.items" :key="key">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="ceesp.Appreciation.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12 border-right-blue">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>{{ceesp.complementairesaapporter.title}}</strong></div>
                            <div class="box-check box-check-text" v-for="(item, key) in ceesp.complementairesaapporter.items" :key="key">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="ceesp.complementairesaapporter.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="box-padding">
                            <div class="text-blue title-mb2"><strong>Date :</strong></div>
                            <div class="row">
                                 <div class="col-12 col-lg-12 col-md-12 mb-2">
                                    <div class="box-input-circle">
                                        <datepicker 
                                            v-model="ceesp.Avis_defficience_Debut.data" 
                                            :locale="language"
                                            inputFormat="MM/yyyy" 
                                            minimumView="month"
                                            class="form-control" 
                                            :placeholder="ceesp.Avis_defficience_Debut.title"
                                            :clearable="true" name="Avis_defficience_Debut" autocomplete="off"
                                            >  
                                            <template v-slot:clear>
                                                <div class="date_clearable" @click="ceesp.Avis_defficience_Debut.data = null"><em>x</em></div>
                                            </template>
                                        </datepicker>

                                    </div>
                                </div>
                                <div class="col-12 col-lg-12 col-md-12">
                                    <div class="box-input-circle">
                                        <datepicker 
                                            v-model="ceesp.Avis_defficience_Fin.data" 
                                            :locale="language"
                                            inputFormat="MM/yyyy" 
                                            minimumView="month"
                                            class="form-control" 
                                            :placeholder="ceesp.Avis_defficience_Fin.title"
                                            :clearable="true" name="Avis_defficience_Fin" autocomplete="off"
                                            >  
                                            <template v-slot:clear>
                                                <div class="date_clearable" @click="ceesp.Avis_defficience_Fin.data = null"><em>x</em></div>
                                            </template>
                                        </datepicker>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>

           
                
<!--&lt;!&ndash; end content main &ndash;&gt;-->

</template>

<script>
import Datepicker from 'vue3-datepicker'
import { enGB, fr, de } from 'date-fns/locale'
export default {
  name: 'ceesp',
  components: {
      Datepicker
  },
  computed : {
      language(){
          return this.$i18n.locale !== 'en' ? this.languages[this.$i18n.locale] : this.languages[enGB]
      }
  },
  data: function(){
       return {
            languages : {enGB, fr, de },
            ceesp : {
                'title' : 'CEESP',
                DecisionduCollegedelaHAS : {
                    title : this.$t('advance.ceesp.DecisionduCollegedelaHAS'),
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                Impactsignificatif : {
                    title : this.$t('advance.ceesp.Impactsignificatif'),
                    items : [
                        {'display' : this.$t('OUI'), 'value' : '2'},
                        {'display': this.$t('NON'), 'value' : '1'},
                    ],
                    data : [],
                    data_exclude : []
                },
                Conclusion : {
                    title : this.$t('advance.ceesp.Conclusion.title'),
                    items : [
                        {'display':this.$t('advance.ceesp.Conclusion.Eligible_evaluation_medico_economique'), 
                                    'value':'1'},
                        {'display' : this.$t('advance.ceesp.Conclusion.Non_eligible_evaluation_medico_economique'), 
                                    'value':'2'}                
                    ],
                    data : [],
                    data_exclude : []
                },
                BaissePrixEngagee :{
                    title : "",
                    items : [
                            {'display' : 'Baisse de prix engagée', 'value' : '1'}
                    ],
                    data : [],
                    data_exclude : []
                },
                DecisionduCollegedelaHAS_Debut : {
                    title : this.$t('date_start'),
                    titleInfo : this.$t('advance.ceesp.DecisionduCollegedelaHAS') + ' ' +  this.$t('date_start'),
                    data : null
                },
                DecisionduCollegedelaHAS_Fin : {
                    title : this.$t('date_end'),
                    titleInfo : this.$t('advance.ceesp.DecisionduCollegedelaHAS') + ' ' +  this.$t('date_end'),
                    data : null
                },
                Avis_defficience : {
                    title : this.$t('advance.ceesp.Avis_defficience'),
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                Appreciation : {
                    title : this.$t('advance.ceesp.Appreciation.title'),
                    items : [
                        {'display': this.$t('advance.ceesp.Appreciation.conforme_sans_reserve'), 'value' : 'Conforme sans réserve'},
                        {'display': this.$t('advance.ceesp.Appreciation.acceptable_avec_reserves_mineures'), 'value' : 'Acceptable avec réserves mineures'},
                        {'display': this.$t('advance.ceesp.Appreciation.acceptable_avec_reserves_importantes'), 'value' : 'Acceptable avec réserves importantes'},
                        {'display': this.$t('advance.ceesp.Appreciation.non_conforme'), 'value': 'Non conforme (réserve majeure)'},
                        {'display': this.$t('advance.ceesp.Appreciation.non_documente'), 'value' : 'Non documenté'}
                    ],
                    data : [],
                    data_exclude : []
                },
                complementairesaapporter : {
                    title : this.$t('advance.ceesp.complementairesaapporter'),
                    items : [
                        {'display': this.$t('OUI'), 'value' : 'oui'},
                        {'display': this.$t('NON'), 'value' : 'Non'}, 
                    ],
                    data : [],
                    data_exclude : []
                },
                Avis_defficience_Debut : {
                    title : this.$t('date_start'),
                    titleInfo :  this.$t('advance.ceesp.Avis_defficience') + ' ' + this.$t('date_start'),
                    data : null
                },
                Avis_defficience_Fin : {
                    title : this.$t('date_end'),
                    titleInfo : this.$t('advance.ceesp.Avis_defficience') + ' ' + this.$t('date_end'),
                    data : null

                }
            },
            lang : localStorage.getItem('language')
      }
  },
  updated() {
        this.$emit("onReturn", { key: "Ceesp", value: this.ceesp });
    },
  mounted() {
        this.$emit("onReturn", { key: "Ceesp", value: this.ceesp });
    },
}
</script>
