<template>

    <div class="box-content-lg">       
        <div class="box-wrap mb-3">
            <div class="box-border-white box-border-white-sm box-shadow">
                <div class="row">
                    <div class="col-lg-1 col-md-1 col-12 text-blue box-inline text-14"><strong>DATE</strong></div>
                    <div class="col-lg-9 col-md-9 col-12">
                        <div class="text-blue">
                            <div class="box-input-circle"  style="margin-right: 40px;">
                                <datepicker 
                                    v-model="datas.date_start.data" 
                                    :locale="language"
                                    inputFormat="MM/yyyy" 
                                    minimumView="month"
                                    class="form-control" 
                                    :placeholder="datas.date_start.title"
                                    :clearable="true" name="date_start" autocomplete="off"
                                    >  
                                    <template v-slot:clear>
                                        <div class="date_clearable" @click="datas.date_start.data = null"><em>x</em></div>
                                    </template>
                                </datepicker>
                            </div>
                            <div class="box-input-circle">
                                <datepicker 
                                    v-model="datas.date_end.data" 
                                    :locale="language"
                                    inputFormat="MM/yyyy" 
                                    minimumView="month"
                                    class="form-control" 
                                    :placeholder="datas.date_end.title"
                                    :clearable="true" name="date_end" autocomplete="off"
                                    >  
                                    <template v-slot:clear>
                                        <div class="date_clearable" @click="datas.date_end.data = null"><em>x</em></div>
                                    </template>
                                </datepicker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-wrap mb-1">
            <div class="box-border-blue">
                <div class="text-blue title-mb2"><strong>{{datas.type_demande.title}}</strong></div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="box-padding">
                            <div v-for="(item, key) in datas.type_demande.items.slice(0,5)" :key="key" class="box-check box-check-text">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="datas.type_demande.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        
                                        {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                        
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="box-padding">
                            <div v-for="(item, key) in datas.type_demande.items.slice(5,15)" :key="key" class="box-check box-check-text">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="datas.type_demande.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        
                                        {{item.display}}
                                    </div>
                                </label>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-wrap mb-1">
            <div class="row box-space">
                <div class="col-sm col-lg-4 box-border-blue mr-right15">
                    <!-- <div class="m-10"> -->
                        <div class="text-blue title-mb2"><strong>{{datas.motif.title}}</strong></div>
                        <div v-for="(item, key) in datas.motif.items" :key="key" class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="item.value" v-model="datas.motif.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{item.display}}
                                </div>
                            </label>
                        </div>
                    <!-- </div> -->
                </div>
           
                <div class="col-sm col-lg-4 box-border-blue mr-right15">
                    <!-- <div class="m-10"> -->
                        <div class="text-blue title-mb2"><strong>{{datas.classification.title}}</strong></div>
                        <div v-for="(item,key) in datas.classification.items" :key="key" class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="item.value" v-model="datas.classification.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                        {{item.display}}
                                </div>
                            </label>
                        </div>
                    <!-- </div> -->
                </div>
                <div class="col-sm box-border-blue">
                    <!-- <div class="m-10"> -->
                        <div class="text-blue title-mb2"><strong>{{datas.type_amm.title}}</strong></div>
                        <div v-for="(item,key) in datas.type_amm.items" :key="key" class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="item.value" v-model="datas.type_amm.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{item.display}}
                                </div>
                            </label>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>

        <div class="box-wrap mb-1">
            <div class="row box-space">
                <div class="col-sm col-lg-4 box-border-blue mr-right15">
                    <!-- <div class="m-10"> -->
                        <div class="text-blue title-mb2"><strong>{{datas.smr.title}}</strong></div>
                        <div v-for="(item,key) in datas.smr.items" :key="key" class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="item.value" v-model="datas.smr.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{item.display}}
                                </div>
                            </label>
                        </div>
                        <hr>
                            <div class="box-check box-check-text" v-for="(item, key) in datas.conditionnel.items" :key="key" >
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="datas.conditionnel.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                        {{$t(datas.conditionnel.title)}}
                                    </div>
                                </label>
                            </div> 
                    <!-- </div> -->
                </div>

                <div class="col-sm col-lg-4 box-border-blue mr-right15">
                    <!-- <div class="m-10"> -->
                        <div class="text-blue title-mb2"><strong>{{datas.asmr_obtenue.title}}</strong></div>
                        <div v-for="(item,key) in datas.asmr_obtenue.items" :key="key" class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="item.value" v-model="datas.asmr_obtenue.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{item.display}}
                                </div>
                            </label>
                        </div>
                    <!-- </div> -->
                </div>

                <div class="col-sm box-border-blue">
                    <!-- <div class="m-10"> -->
                        <div class="text-blue title-mb2"><strong>{{datas.prise_en_charge.title}}</strong></div>
                        <div v-for="(item,key) in datas.prise_en_charge.items" :key="key" class="box-check box-check-text">
                            <label>
                                <input type="checkbox" :value="item.value" v-model="datas.prise_en_charge.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                        {{item.display}}
                                </div>
                            </label>
                        </div> 
                    <!-- </div> -->
                </div>
            </div>
        </div>

        <div class="box-wrap mb-1">
            <div class="row box-space">
                <div class="col-sm box-border-blue mr-right15">
                    <!-- <div class="m-10"> -->
                        <div class="text-blue title-mb2"><strong>{{datas.isp.title}}</strong></div>
                        <div v-for="(item,key) in datas.isp.items" :key="key" class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="item.value" v-model="datas.isp.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{item.display}}
                                </div>
                            </label>
                        </div>
                    <!-- </div> -->
                </div>

                <div class="col-sm box-border-blue">
                    <!-- <div class="m-10"> -->
                        <div class="text-blue title-mb2"><strong>{{datas.besoin_medical.title}}</strong></div>
                        <div v-for="(item,key) in datas.besoin_medical.items" :key="key" class="box-check box-check-text">
                            <label> 
                                <input type="checkbox" :value="item.value" v-model="datas.besoin_medical.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{item.display}}
                                </div>
                            </label>
                        </div> 
                    <!-- </div> -->
                </div>
            </div>
        </div>

        <div class="box-wrap mb-1">
            <div class="row box-space">
                <div class="col-sm box-border-blue mr-right15">
                    <div class="text-blue title-mb2"><strong>{{datas.comparaison_indirecte.title}}</strong></div>
                    <div v-for="(item,key) in datas.comparaison_indirecte.items" :key="key" class="box-check box-check-text box-inline" :style="(key/2 == 0)? 'margin-right: 30px;': '' ">
                        <label> 
                            <input type="checkbox" :value="item.value" v-model="datas.comparaison_indirecte.data">
                            <span class="check-recherche"></span>
                            <div class="txt-check text-gray">
                                {{item.display}}
                            </div>
                        </label>
                    </div> 
                </div>
                <div class="col-sm box-border-blue">                    
                    <div class="text-blue title-mb2"><strong>{{datas.mk_orphelin.title}}</strong></div>
                    <div v-for="(item,key) in datas.mk_orphelin.items" :key="key" class="box-check box-check-text box-inline" :style="(key/2 == 0)? 'margin-right: 30px;': '' ">
                        <label> 
                            <input type="checkbox" :value="item.value" v-model="datas.mk_orphelin.data">
                            <span class="check-recherche"></span>
                            <div class="txt-check text-gray">
                                {{item.display}}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
            
        <div class="box-wrap">
            <div class="row box-space">
                <div class="col-sm box-border-blue">
                    <div class="m-10">
                        <div class="text-blue title-mb2"><strong>{{datas.phase_contradictoire.title}} </strong></div>
                        <div v-for="(item,key) in datas.phase_contradictoire.items" :key="key" class="box-check box-check-text box-inline" :style="(key/2==0)? 'margin-right: 30px;':''">
                            <label>
                                <input type="checkbox" :value="item.value" v-model="datas.phase_contradictoire.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{item.display}}
                                </div>
                            </label>
                        </div>                          
                    </div>
                </div>
            </div>
        </div>

        <div class="box-wrap mb-1" v-if="isAdmin()">
            <div class="title-text text-blue">INTERNAL ACCESS ONLY</div>
            <div class="row box-space">
                <div class="col-sm box-border-blue">
                    <div class="m-10">
                        <div class="text-blue title-mb2 box-inline" style="margin-right: 30px;"><strong>{{datas.tc_has_id.title}}</strong></div>
                        <div class="box-input box-input-2" >  
                            <autocomplete :items="autocomplete_tc_has_id" inputname="tc_has_id" @onAutocomplete="onAutocomplete" methodfor="advance"  :clear="true"  v-model="datas.tc_has_id.data"   :classinputname="'form-cs'" :placeholder="datas.tc_has_id.placeholder"     :empty="false"  />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row box-space">
                <div class="col-sm box-border-blue">
                    <div class="m-10">
                        <div class="text-blue title-mb2"><strong>{{datas.type_procedure.title}}</strong></div>
                        <div v-for="(item,key) in datas.type_procedure.items" :key="key" class="box-check box-check-text box-inline" style="margin-right: 30px;">
                            <label>
                                <input type="checkbox" :value="item.value" v-model="datas.type_procedure.data">
                                <span class="check-recherche"></span>
                                <div class="txt-check text-gray">
                                    {{item.display}}
                                </div>
                            </label>
                        </div>
                    </div>
                </div>             
                <div class="row box-space mb-2">
                    <div class="col-sm box-border-blue">
                        <div class="m-10">
                            <div>
                                <div class="text-blue title-mb2 box-inline" style="margin-right: 30px;">
                                    <strong>{{datas.population_nombre.title}}</strong></div>
                                <div class="box-inline">                                 
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle border-light-gray dropdown-cs"
                                                type="button" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                             {{datas.population_nombre.selected}}
                                            <span><em class="fa fa-chevron-down"></em> </span>
                                        </button>
                                        <ul class="dropdown-menu dropdown-custom" aria-labelledby="dropdownMenuButton1">   
                                            <li  v-for="(item,key) in datas.population_nombre.items" :key="key">                                                
                                                <a class="dropdown-item" style="cursor: pointer;" @click="setVal(item.value,item.display)">{{item.display}}</a>
                                            </li> 
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</template>

<script> 
import Datepicker from 'vue3-datepicker'
import { isAdmin  } from '@/utils';
import Autocomplete from '@/components/elements/autocomplete.vue';
import { enGB, fr, de } from 'date-fns/locale'

export default {
    name: 'transparenceTC',
    components: { 
        Datepicker,
        Autocomplete
    },
    data:  function(){
        return {
            languages : {enGB, fr, de },
            datas : {
                title : "CT (Transparence)",
                date_start : {
                    title : this.$t('date_start'), 
                    data : null
                },
                date_end : {
                    title : this.$t('date_end'), 
                    data : null
                },
                type_demande: {
                    title : this.$t('advance.TransparenceTC.type_demande.title'), 
                    items : [
                        {value : "INS", display : this.$t('advance.TransparenceTC.type_demande.INS')},
                        {value : "EIT", display : this.$t('advance.TransparenceTC.type_demande.EIT')},
                        {value : "REEVAL", display : this.$t('advance.TransparenceTC.type_demande.REEVAL')},
                        {value : "REINS", display : this.$t('advance.TransparenceTC.type_demande.REINS')},
                        {value : "RADIATION", display : this.$t('advance.TransparenceTC.type_demande.RADIATION')},                        
                        {value : "Remboursement non demandé", display : this.$t('advance.TransparenceTC.type_demande.Remboursement_non_demande')},
                        {value : "NEWDATA", display : this.$t('advance.TransparenceTC.type_demande.NEWDATA')},
                        {value : "AUTRE", display : this.$t('advance.TransparenceTC.type_demande.AUTRE')},
                        {value : "Modification de l’indication", display : this.$t('advance.TransparenceTC.type_demande.Modification_de_indication')},
                        {value : "Prise en charge temporaire", display : this.$t('advance.TransparenceTC.type_demande.Prise_en_charge_temporaire')},
                        //{value : "Request of authorities", display : "Ré-évaluation à la demande des autorités"},                        
                        //{value : "Request of sponsor", display : "Ré-évaluation à la demande la firme"},
                    ],
                    data : [],
                    data_exclude : []
                },
                conditionnel: {
                    title : this.$t('SMR conditionnel'), 
                    items : [
                        {value : "1", display : this.$t('Yes')},
                        ],
                    data : [],
                    data_exclude : []
                },
                motif : {
                    title : this.$t('advance.TransparenceTC.motif.title'), 
                    items : [
                        {value : "SS", display : this.$t('advance.TransparenceTC.motif.SS')},
                        {value : "SSCOLL", display : this.$t('advance.TransparenceTC.motif.SSCOLL')},
                        {value : "COLL", display : this.$t('advance.TransparenceTC.motif.COLL')},
                    ],
                    data : [],
                    data_exclude : []
                },
                classification:{
                    title : this.$t('advance.TransparenceTC.classification.title'),
                    items : [
                        {value : "Nouvelle entité", display : this.$t('advance.TransparenceTC.classification.nouvelle_entite')}, //recheck mockup => Médicament d’exception 
                        {value : "Nouvelle indication", display : this.$t('advance.TransparenceTC.classification.nouvelle_indication')},
                        {value : "Nouvelle association", display : this.$t('advance.TransparenceTC.classification.nouvelle_association')},
                        {value : "Complement de gamme", display : this.$t('advance.TransparenceTC.classification.complement_de_gamme')},
                        {value : "Nouveau produit", display : this.$t('advance.TransparenceTC.classification.nouveau_produit')},
                        {value : "Modification de l'indication", display : this.$t('advance.TransparenceTC.classification.modification_de_indication')},
                        {value : "Autre", display : this.$t('advance.TransparenceTC.classification.autre')},
                        {value : "Réévaluation", display : this.$t('advance.TransparenceTC.classification.Réévaluation')},
                    ],
                    data : [],
                    data_exclude : []
                },
                type_amm:{
                    title : this.$t('advance.TransparenceTC.type_amm.title'),
                    items : [
                        {value : "AMM conditionnelle", display : this.$t('advance.TransparenceTC.type_amm.AMM conditionnelle')},
                        {value : "AMM sous circonstances exceptionnelles", display : this.$t('advance.TransparenceTC.type_amm.AMM sous circonstances exceptionnelles')},
                        {value : "Biosimilaire", display : this.$t('advance.TransparenceTC.type_amm.Biosimilaire')},
                        {value : "Hybride", display : this.$t('advance.TransparenceTC.type_amm.Hybride')},
                        {value : "Generic", display : this.$t('advance.TransparenceTC.type_amm.Generic')},
                        // {value : "Similaire", display : "Similaire"},
                    ],
                    data : [],
                    data_exclude : []
                },
                smr : {
                    title : this.$t('advance.TransparenceTC.smr.title'),
                    items :[
                        {value : "important", display : this.$t('transparence.smr.important')},
                        {value : "modéré", display : this.$t('transparence.smr.modéré')},
                        {value : "faible", display : this.$t('transparence.smr.faible')},
                        {value : "insuffisant", display : this.$t('transparence.smr.insuffisant')},
                        {value : "Remboursement non sollicité par la firme", display : this.$t('transparence.smr.Remboursement non sollicité par la firme')},
                    ],
                    data : [],
                    data_exclude : []
                },
                asmr_obtenue : {
                    title : this.$t('advance.TransparenceTC.asmr'),
                    items :[
                        {value : "I", display : "I"},
                        {value : "II", display : "II"},
                        {value : "III", display : "III"},
                        {value : "IV", display : "IV"},
                        {value : "V", display : "V"},

                    ],
                    data : [],
                    data_exclude : []
                },
                prise_en_charge :{ //IndicationJoInfo.particularites_ss
                    title : this.$t('advance.TransparenceTC.prise_en_charge.title'),
                    items :[
                        {value : "Prise en charge", display : this.$t('Prise en charge')},
                        {value : "En cours", display : this.$t('En cours')},                       
                        {value : "Non pris en charge", display : this.$t('Non pris en charge')},     
                        {value : "Radiation", display : this.$t('Radiation')},              
                        {value : "Prise en charge précoce", display : this.$t('Prise en charge précoce')},
                        {value : "Prise en charge à titre dérogatoire", display : this.$t('Prise en charge à titre dérogatoire')},
                    ],
                    data : [],
                    data_exclude : []
                },              
                // autres_criteres :{
                //     title : "Autres critères de recherche ",
                //     items :[
                //         {value : "xxx", display : "Médicament orphelin"},
                //         {value : "xxx", display : "Médicament d’exception"},
                //         {value : "xxx", display : "Modification SMR/ASMR post-REEVAL"},
                //         {value : "xxx", display : "hase contradictoire"},
                //     ],
                //     data : []
                // },
                  isp:{
                    title : this.$t('advance.TransparenceTC.isp.title'),
                    items :[
                        {value : "important", display : this.$t('advance.TransparenceTC.isp.important')},
                        {value : "modéré", display : this.$t('advance.TransparenceTC.isp.modere')},
                        {value : "faible", display : this.$t('advance.TransparenceTC.isp.faible')},
                        {value : "insuffisant", display : this.$t('advance.TransparenceTC.isp.insuffisant')},
                        {value : "absence", display : this.$t('advance.TransparenceTC.isp.absence')},
                        {value : "Impact négatif", display : this.$t('advance.TransparenceTC.isp.impact_negatif')},
                        {value : "Susceptible d’avoir un impact", display : this.$t('advance.TransparenceTC.isp.susceptible_avoir_un_impact')},
                    ],
                    data : [],
                    data_exclude : []
                },
                besoin_medical:{
                    title : this.$t('advance.TransparenceTC.besoin_medical.title'),
                    items :[
                        {value : "Couvert", display : this.$t('advance.TransparenceTC.besoin_medical.couvert')},
                        {value : "Non couvert", display : this.$t('advance.TransparenceTC.besoin_medical.non_couvert')},
                        {value : "Partiellement couvert", display : this.$t('advance.TransparenceTC.besoin_medical.partiellement_couvert')},
                        {value : "Non précisé", display : this.$t('advance.TransparenceTC.besoin_medical.non_precise')},
                    ],
                    data : [],
                    data_exclude : []
                },
                comparaison_indirecte:{
                    title : this.$t('advance.TransparenceTC.comparaison_indirecte.title'),
                    items :[
                        {value : "TRS_IC", display : this.$t('advance.TransparenceTC.comparaison_indirecte.TRS_IC')},
                        {value : "TRS_IC_data", display : this.$t('advance.TransparenceTC.comparaison_indirecte.TRS_IC_data')},
                    ],
                    data : [],
                    data_exclude : []
                },
                mk_orphelin:{
                    title : this.$t('simple.Orphan designation'),
                    items :[
                        {value : "1", display : this.$t('Orphan drug') },
                        {value : "2", display : this.$t('Previously Orphan') },
                    ],
                    data : [],
                    data_exclude : []
                },
                phase_contradictoire :{
                    title : "PHASE CONTRADICTOIRE",
                    items :[
                        {value : "TRS_phase_contradictoire", display : "Phase contradictoire"},
                        {value : "TRS_modification", display : "Modification"}, 

                    ],
                    data : [],
                    data_exclude : []
                },
                type_procedure :{
                    title : "TYPE DE PROCÉDURE",
                    items :[
                        {value : "PIC", display : "PIC"},
                        {value : "PIS", display : "PIS"},
                        {value : "QD", display : "QD"}, 
                        {value : "Non renseigné", display : this.$t("Non renseigné")}, 
                    ],
                    data : [],
                    data_exclude : []
                },
                population_nombre : {
                    title : "POPULATION (NOMBRE)",
                    items :[
                        {value : "1", display : "< 50"},
                        {value : "2", display : "50 - 99"},
                        {value : "3", display : "100 - 499"}, 
                        {value : "4", display : "500 - 999"}, 
                        {value : "5", display : "1 000 - 9 999"}, 
                        {value : "6", display : "10 000 - 300 000"}, 
                        {value : "7", display : "> 300 000"}, 
                         
                    ],                    
                    data : [],
                    selected : "-----",
                    show : false
                },
                tc_has_id : {
                    title : 'TC HAS ID',
                    placeholder : 'CT-6364',
                    data : [],
                    data_exclude : []
                },
            },  
            lang : localStorage.getItem('language')
        }
    },   
    updated() {
        this.$emit("onReturn", { key: "Transparence", value: this.datas });
    },
    mounted(){ 
        this.loadAutocomplete()    
        this.$emit("onReturn", { key: "Transparence", value: this.datas });
    },   
    methods: {
        loadAutocomplete(){ 
            const keyLoadAutoComplete = []
            if(this.$store.getters['autocomplete/tc_has_id'].length === 0){
                keyLoadAutoComplete.push('tc_has_id')
            } 
            if(keyLoadAutoComplete.length > 0){
                this.$store.dispatch("autocomplete/loadAutocomplete", keyLoadAutoComplete)
            }
        },
        setVal(val,disp){
            this.datas.population_nombre.show = false;    
            this.datas.population_nombre.data = [];
            this.datas.population_nombre.selected = disp;
            if (val != ''){
                this.datas.population_nombre.data.push(val); 
            }             
        },
        isAdmin,
        onAutocomplete(param){  
             console.log('load autocom tc')
            // console.log(param)
            if(this.datas[param.key].data.indexOf(param.value) === -1){
                this.datas[param.key].data.push(param.value);
            }
        }, 
    },
    computed : {
        autocomplete_tc_has_id () {  
            return this.$store.getters['autocomplete/tc_has_id']            
        },
        language(){
            return this.$i18n.locale !== 'en' ? this.languages[this.$i18n.locale] : this.languages[enGB]
        }
    }, 
}
</script>
<style scoped>
#dd_population_number {
    display: inline-block !important;
}
.box-input-2{
    position:relative;
    display: inline-block;
    padding: 0px;
}
</style>
