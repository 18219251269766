<template>
    <div class="box-info">
        <div id="box-info-fix" :style="toggleNavClass()">
        <div class="box-border-white box-shadow">
            <div class="tb-title-box text-blue">{{$t('advance.resume_search')}}</div>
            <div class="content-box">
                
                <template v-for="(main, keyMain) in ['include', 'exclude']" :key="keyMain">
                    <div class="text-blue text-14" v-if="checkEmptyLavel1(datas, main)" ><strong>{{$t(main)}}</strong></div>

                    <template v-for="(items, key) in datas" :key="keyMain+'.'+key">
                        <template v-if="checkEmptyLavel2(items, main)">
                            <div class="box-resume" >
                                <div class="text-blue text-14"><strong>{{items.title}} :</strong></div>
                                <div class="box-list">
                                    <ul class="sub-list">
                                        <template v-for="(item, key2) in items" :key="keyMain+'.'+key+'.'+key2">                                        
                                            <template v-if="checkEmptyLavel3(item, main)">
                                                <template v-if="main === 'include'">
                                                    <template v-if="Array.isArray(item.data) && check_include_notin_exclude(item.data, item) > 1">
                                                        <li>
                                                            <strong v-if="item.title !== undefined || item.titleInfo !== undefined">{{ item.titleInfo !== undefined ? item.titleInfo : item.title}} :</strong>
                                                            <ul class="sub-list" >
                                                                <template v-for="(value, key3) in item.data" :key="keyMain+'.'+key+'.'+key2+'.'+key3"> 
                                                                    <li v-if="item.data_exclude === undefined || (item.data_exclude !== undefined && item.data_exclude.indexOf(value) === -1 ) " class="li-flex">
                                                                        <div class="width270">
                                                                        {{getDisplay(value, item, main, key2)}}
                                                                        </div>
                                                                        <div class="box-action-keyword" >
                                                                            <!-- <div class="action-keyword">{{ $t('include') }}</div> |  -->
                                                                            <div class="action-keyword" v-if="item.data_exclude !== undefined" @click="exclude(key, key2, value)">{{ $t('exclude') }} | </div>  
                                                                            <div class="pointer"><span class="close-keyword" @click="remove_keyword(key, key2, value, main)"></span></div>
                                                                        </div>
                                                                    </li>
                                                                </template>
                                                            </ul>  
                                                        </li>
                                                    </template>
                                                    <template v-else-if="check_include_notin_exclude(item.data, item)">
                                                        <li class="li-flex">
                                                            <div class="width270">
                                                                <strong v-if="item.title !== undefined || item.titleInfo !== undefined">{{ item.titleInfo !== undefined ? item.titleInfo : item.title}} :</strong>
                                                                {{getDisplay(item.data, item, main, key2)}}
                                                            </div>
                                                            <div class="box-action-keyword" >
                                                                <!-- <div class="action-keyword">{{ $t('include') }}</div> |  -->
                                                                <div class="action-keyword" v-if="item.data_exclude !== undefined" @click="exclude(key, key2, item.data)">{{ $t('exclude') }} | </div> 
                                                                <div class="pointer"><span class="close-keyword" @click="remove_keyword(key, key2, item.data, main)"></span></div>
                                                            </div>
                                                        </li>
                                                    </template>     

                                                </template>

                                                <template v-else-if="main === 'exclude'">
                                                    <template v-if="Array.isArray(item.data_exclude) && item.data_exclude.length > 1">
                                                        <li  >
                                                            <strong v-if="item.title !== undefined || item.titleInfo !== undefined">{{ item.titleInfo !== undefined ? item.titleInfo : item.title}} :</strong>
                                                            <ul class="sub-list" >
                                                                <li v-for="(value, key3) in item.data_exclude"  :key="keyMain+'.'+key+'.'+key2+'.'+key3" class="li-flex"> 
                                                                    <div class="width270">
                                                                    {{getDisplay(value, item, main, key2)}}
                                                                    </div>
                                                                    <div class="box-action-keyword"> 
                                                                        <div class="action-keyword" @click="include(key, key2, value)">{{ $t('include') }} | </div> 
                                                                        <div class="pointer"><span class="close-keyword" @click="remove_keyword(key, key2, value, main)"></span></div>
                                                                    </div>
                                                                </li>
                                                            </ul>  
                                                        </li>
                                                    </template>
                                                    <template v-else>
                                                        <li  class="li-flex">
                                                            <div class="width270">
                                                                <strong v-if="item.title !== undefined || item.titleInfo !== undefined">{{ item.titleInfo !== undefined ? item.titleInfo : item.title}} :</strong>
                                                                {{getDisplay(item.data_exclude, item, main, key2)}}
                                                            </div>
                                                            <div class="box-action-keyword"> 
                                                                <div class="action-keyword" @click="include(key, key2, item.data_exclude)">{{ $t('include') }} | </div> 
                                                                <div class="pointer"><span class="close-keyword" @click="remove_keyword(key, key2, item.data_exclude, main)"></span></div>
                                                            </div>
                                                        </li>
                                                    </template>   
                                                </template>    

                                            </template>  
                                            
                                        </template> 
                                    </ul>
                                </div>   

                            </div>
                        </template>          
                    </template>
                </template>

                 
            </div>
        </div>
        
        <div style="margin-top: 30px;">            
            <button type="submit" class="btn-border-blue btn-blue text-center mr-3 w-100" style="padding: 8px;">{{$t('Launch the search')}}</button>
        </div>
           
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { isValidDate } from '@/utils';
export default {
    name: "infoSearch",
    components: { 

    },
    data:  function(){
        return {
            active : false,
        }
    },
    props: {
        datas: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    methods: {        
        checkEmptyLavel1(datas, type){
            for (const items of Object.values(datas)) {                  
                if(this.checkEmptyLavel2(items, type)){
                    return true;
                }               
            }
            return false
        },
        checkEmptyLavel2(items, type){
            for (const item of Object.values(items)) {    
                if(this.checkEmptyLavel3(item, type)){
                    return true;
                }
            }          
            return false
        },
        checkEmptyLavel3(item, type){
            if(type === "include" && item.data !== undefined){  
                return this.check_include_notin_exclude(item.data, item);
            }else if(type === "exclude" && item.data_exclude !== undefined){  
                if(Array.isArray(item.data_exclude) && item.data_exclude.length > 0 ){
                    return true;                        
                }else if(typeof item.data_exclude == 'string' && item.data_exclude !== ''){
                    return true;
                }
            }
            return false
        },
        check_include_notin_exclude(include, obj){
            if(obj.data_exclude !== undefined){
                if(Array.isArray(include) && include.length > 0){                        
                    const difference = include.filter(x => !obj.data_exclude.includes(x));
                    return difference.length;                 
                }else if(typeof include == 'string' && include !== '' && obj.data_exclude === ''){
                    return include.length;   
                }else{
                    return ""
                }
            }
            if(this.isValidDate(include)){
                return true;
            }
            return include === null ? "" : include.length;            
        },
        isValidDate,
        getValue(data, Obj, type){
            let result = data
           
            if(type === "include" && Array.isArray(data) && data.length > 0 && Obj.data_exclude !== undefined){
                for (const item of data) { 
                    if(Obj.data_exclude.indexOf(item) === -1){
                        result = item
                    }
                }
            }else if(Array.isArray(data) && data.length === 1){
                result = data[0];
            }

            return result; 
        },
        getDisplay(data, Obj, type, key2){ 

            let result = this.getValue(data, Obj, type);
             
            if (Obj.items) {     
                for (const value of Object.values(Obj.items)) { 
                    if(result === value.value){                                            
                        result = value.display;
                    } 
                }
            }
            if(this.isValidDate(result)){
                if(key2 === "DateJO"){
                    return moment(result).format("DD-MM-YYYY")
                }else{
                    return moment(result).format("MM/YYYY")
                }                
            }
            return result;
        },
        exclude(key, key2, data){             
            let tempData = this.datas[key]
            let value = this.getValue(data, tempData[key2], "include");             
            console.log(tempData[key2])
            if(Array.isArray(tempData[key2]['data']) ){
                tempData[key2]['data_exclude'].push(value);
            }else{ // String
                tempData[key2]['data_exclude'] = value;
            }
            console.log(tempData[key2])
            this.$emit("onReturn", { key: key, value: tempData });    
        },
        include(key, key2, data){  
            let tempData = this.datas[key]
            let value = this.getValue(data, tempData[key2], "exclude");  
            console.log(tempData[key2])
            if(Array.isArray(tempData[key2]['data_exclude']) ){
                const keyArr = tempData[key2]['data_exclude'].indexOf(value); 
                tempData[key2]['data_exclude'].splice(keyArr,  1); //unset key from array                
            }else{ // String
                tempData[key2]['data_exclude'] = "";
            }
            console.log(tempData[key2])
            //console.log(tempData)
            this.$emit("onReturn", { key: key, value: tempData }); 
        },
        remove_keyword(key, key2, data, type){
            let tempData = this.datas[key]
            let value = this.getValue(data, tempData[key2], type); 
            
            
            if(Array.isArray(tempData[key2]['data']) ){
                const keyArr = tempData[key2]['data'].indexOf(value); 
                tempData[key2]['data'].splice(keyArr,  1); //unset key from array                
            }else if(this.isValidDate(tempData[key2]['data'])){
                tempData[key2]['data'] = null;
            }else{ // String
                tempData[key2]['data'] = "";
            }

            if(type === "exclude"){
                if(Array.isArray(tempData[key2]['data_exclude']) ){
                    const keyArr = tempData[key2]['data_exclude'].indexOf(value); 
                    tempData[key2]['data_exclude'].splice(keyArr,  1); //unset key from array                
                }else if(this.isValidDate(tempData[key2]['data_exclude'])){
                    tempData[key2]['data_exclude'] = null;
                }else{ // String
                    tempData[key2]['data_exclude'] = "";
                }
            }
            this.$emit("onReturn", { key: key, value: tempData }); 
        },
        toggleNavClass(){
            if(this.active === false){
            return 'position: static;'
            } else {
            return 'position: fixed !important; top:20%;'
            }
        }
    },
    mounted(){
        window.document.onscroll = () => {
            let navBar = document.getElementById('box-info-fix');
            if(navBar && window.scrollY > navBar.offsetTop - 100){
                this.active = true;
            } else {
                this.active = false;
            }
        }
    }, 
}
</script>

<style scoped> 
.box-resume{margin-bottom: 10px;}
.sub-list{
    padding-left: .8rem;
}
.li-flex{display: flex}
.width270{width: 244px;}
.box-border-white{ padding: 20px 15px;}
.box-info{width: 523px;}
.box-action-keyword{
    position:relative;
    font-size: 0.8em;
    width: 68px;
    margin-left: auto;
    /* padding-top: 5px; */
}
.pointer{ cursor: pointer; }

.action-keyword{ 
    text-align: right;
    margin-right: 18px;
    cursor: pointer;
}
.close-keyword {
    width: 15px;
    height: 15px;
    display: block;
    cursor: pointer; 
    background-image: url("../../assets/images/icon/table-checklist-close.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position:absolute;
    top:0;
    right: 0;
}
@media screen and (max-height : 750px){
    #box-info-fix{
        position: static !important;
    }
    .box-border-white{
        max-height: 100% !important;
    }
    
}

@media screen and (min-height : 750px){
    .box-border-white{
        max-height: 450px !important;
    } 
}

@media screen and (max-width: 991px){
    #box-info-fix{
        position: static !important; 
        width:100%;
    }
    .content-box{ 
        overflow: hidden;
    }    
}

@media screen and (min-width: 991px){
    #box-info-fix{
        position: fixed; 
    }
    .box-border-white{
        max-height: 420px;
    }
    .content-box{
        max-height: 350px;
        overflow: auto;
    } 
}

@media screen and (min-width: 1090px){
    #box-info-fix{
        width:380px;
    }
}
</style>