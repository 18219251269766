<template>

    <div class="box-content-lg">
        <div class="box-wrap">
            <div class="row">
                <div class="col-sm col-lg-6">
                    <div class="title-text text-blue"><strong>{{$t('advance.prix.SECURITE_SOCIALE')}}</strong></div>
                    <div class="box-border-blue">
                        <table class="table-prix">
                            <tbody>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :disabled="disabledInput(prix.InscriptionSSduCIP_Debut.data, prix.InscriptionSSduCIP_Fin.data)" :value="prix.InscriptionSSduCIP.items[0].value" v-model="prix.InscriptionSSduCIP.data" name="InscriptionSSduCIP">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.InscriptionSSduCIP.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.InscriptionSSduCIP_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.InscriptionSSduCIP_Debut.title"
                                                    :clearable="true" name="InscriptionSSduCIP_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.InscriptionSSduCIP.data)" 
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.InscriptionSSduCIP_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                                
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.InscriptionSSduCIP_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.InscriptionSSduCIP_Fin.title"
                                                    :clearable="true" name="InscriptionSSduCIP" autocomplete="off"
                                                    :disabled="disabledInput(prix.InscriptionSSduCIP.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.InscriptionSSduCIP_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.PrixSS.items[0].value" :disabled="disabledInput(prix.PrixSS_Debut.data, prix.PrixSS_Fin.data)" v-model="prix.PrixSS.data" name="PrixSS">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                               {{prix.PrixSS.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.PrixSS_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.PrixSS_Debut.title"
                                                    :clearable="true" name="PrixSS_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.PrixSS.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.PrixSS_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.PrixSS_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.PrixSS_Fin.title"
                                                    :clearable="true" name="PrixSS_Fin" autocomplete="off"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.PrixSS_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="border-none">
                                <td>
                                    <div class="box-check box-check-text">
                                    <label>
                                        <input type="checkbox" :value="prix.ApplicationPrixSS.items[0].value" v-model="prix.ApplicationPrixSS.data" :disabled="disabledInput(prix.ApplicationPrixSS_Debut.data, prix.ApplicationPrixSS_Fin.data)" name="ApplicationPrixSS_Fin">
                                        <span class="check-recherche"></span>
                                        <div class="txt-check text-blue-bolder font-14">
                                            {{prix.ApplicationPrixSS.title}} :
                                        </div>
                                    </label>
                                </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.ApplicationPrixSS_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.ApplicationPrixSS_Debut.title"
                                                    :clearable="true" name="ApplicationPrixSS_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.ApplicationPrixSS.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.ApplicationPrixSS_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.ApplicationPrixSS_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.ApplicationPrixSS_Fin.title"
                                                    :clearable="true" name="ApplicationPrixSS" autocomplete="off"
                                                    :disabled="disabledInput(prix.ApplicationPrixSS.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.ApplicationPrixSS_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>                                                

                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="box-check box-check-text">
                                    <label style="padding-left: 25px;">
                                        <!--<input type="checkbox">-->
                                        <!--<span class="check-recherche"></span>-->
                                        <div class="txt-check text-blue-bolder font-14">
                                            {{prix.Variation_du_prixSS.title}} :
                                        </div>
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="box-check box-check-text">
                                            <label> 
                                                <input type="checkbox" :value="prix.Variation_du_prixSS.items.Augmentation.value" v-model="prix.Variation_du_prixSS.data" name="Variation_du_prixSS">
                                                <span class="check-recherche"></span>
                                                <div class="txt-check text-gray">
                                                    {{prix.Variation_du_prixSS.items.Augmentation.display}}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="box-check box-check-text">
                                            <label>
                                                <input type="checkbox" :value="prix.Variation_du_prixSS.items.Baisse.value" v-model="prix.Variation_du_prixSS.data" name="Variation_du_prixSS">
                                                <span class="check-recherche"></span>
                                                <div class="txt-check text-gray">
                                                    {{prix.Variation_du_prixSS.items.Baisse.display}}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.RadiationSS_du_CIP.items[0].value" :disabled="disabledInput(prix.RadiationSS_du_CIP_Debut.data, prix.RadiationSS_du_CIP_Fin.data)" v-model="prix.RadiationSS_du_CIP.data" name="RadiationSS_du_CIP">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.RadiationSS_du_CIP.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.RadiationSS_du_CIP_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.RadiationSS_du_CIP_Debut.title"
                                                    :clearable="true" name="RadiationSS_du_CIP_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.RadiationSS_du_CIP.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.RadiationSS_du_CIP_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>   
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.RadiationSS_du_CIP_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.RadiationSS_du_CIP_Fin.title"
                                                    :clearable="true" name="RadiationSS_du_CIP_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.RadiationSS_du_CIP.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.RadiationSS_du_CIP_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-sm col-lg-6">
                    <div class="title-text text-blue"><strong>{{$t('advance.prix.T2A_LISTE_EN_SUS')}}</strong></div>
                    <div class="box-border-blue">
                        <table class="table-prix">
                            <tbody>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.InscriptionT2A_de_UCD.items[0].value" v-model="prix.InscriptionT2A_de_UCD.data" :disabled="disabledInput(prix.InscriptionT2A_de_UCD_Debut.data, prix.InscriptionT2A_de_UCD_Debut.data)" name="InscriptionT2A_de_UCD">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.InscriptionT2A_de_UCD.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.InscriptionT2A_de_UCD_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.InscriptionT2A_de_UCD_Debut.title" 
                                                    :clearable="true" name="InscriptionT2A_de_UCD_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.InscriptionT2A_de_UCD.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.InscriptionT2A_de_UCD_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.InscriptionT2A_de_UCD_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.InscriptionT2A_de_UCD_Fin.title"
                                                    :clearable="true" name="InscriptionT2A_de_UCD_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.InscriptionT2A_de_UCD.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.InscriptionT2A_de_UCD_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.PrixT2A.items[0].value" v-model="prix.PrixT2A.data" :disabled="disabledInput(prix.PrixT2A_Debut.data, prix.PrixT2A_Fin.data)" name="PrixT2A">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.PrixT2A.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.PrixT2A_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.PrixT2A_Debut.title"
                                                    :clearable="true" name="PrixT2A_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.PrixT2A.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.PrixT2A_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.PrixT2A_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.PrixT2A_Fin.title"
                                                    :clearable="true" name="PrixT2A_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.PrixT2A.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.PrixT2A_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="border-none">
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.ApplicationPrixT2A.items[0].value" v-model="prix.ApplicationPrixT2A.data" :disabled="disabledInput(prix.ApplicationPrixT2A_Debut.data, prix.ApplicationPrixT2A_Fin.data)" name="ApplicationPrixT2A">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.ApplicationPrixT2A.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.ApplicationPrixT2A_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.ApplicationPrixT2A_Debut.title"
                                                    :clearable="true" name="ApplicationPrixT2A_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.ApplicationPrixT2A.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.ApplicationPrixT2A_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.ApplicationPrixT2A_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.ApplicationPrixT2A_Fin.title"
                                                    :clearable="true" name="ApplicationPrixT2A_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.ApplicationPrixT2A.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.ApplicationPrixT2A_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="box-check box-check-text">
                                    <label style="padding-left: 25px;">
                                        <!--<input type="checkbox">-->
                                        <!--<span class="check-recherche"></span>-->
                                        <div class="txt-check text-blue-bolder font-14">
                                            {{prix.Variation_du_prixT2A.title}} :
                                        </div>
                                    </label>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="box-check box-check-text">
                                            <label> 
                                                <input type="checkbox" :value="prix.Variation_du_prixT2A.items.Augmentation.value" v-model="prix.Variation_du_prixT2A.data" name="Variation_du_prixT2A">
                                                <span class="check-recherche"></span>
                                                <div class="txt-check text-gray">
                                                    {{prix.Variation_du_prixT2A.items.Augmentation.display}}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="box-check box-check-text">
                                            <label> 
                                                <input type="checkbox" :value="prix.Variation_du_prixT2A.items.Baisse.value" v-model="prix.Variation_du_prixT2A.data" name="Variation_du_prixT2A">
                                                <span class="check-recherche"></span>
                                                <div class="txt-check text-gray">
                                                    {{prix.Variation_du_prixT2A.items.Baisse.display}}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                    <label>
                                        <input type="checkbox" :value="prix.RadiationT2A_de_UCD.items[0].value" v-model="prix.RadiationT2A_de_UCD.data" :disabled="disabledInput(prix.RadiationT2A_de_UCD_Debut.data, prix.RadiationT2A_de_UCD_Fin.data)" name="RadiationT2A_de_UCD">
                                        <span class="check-recherche"></span>
                                        <div class="txt-check text-blue-bolder font-14">
                                            {{prix.RadiationT2A_de_UCD.title}} :
                                        </div>
                                    </label>
                                </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.RadiationT2A_de_UCD_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.RadiationT2A_de_UCD_Debut.title"
                                                    :clearable="true" name="RadiationT2A_de_UCD_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.RadiationT2A_de_UCD.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.RadiationT2A_de_UCD_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.RadiationT2A_de_UCD_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.RadiationT2A_de_UCD_Fin.title"
                                                    :clearable="true" name="RadiationT2A_de_UCD_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.RadiationT2A_de_UCD.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.RadiationT2A_de_UCD_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-wrap">
            <div class="row">
                <div class="col-sm col-lg-6">
                    <div class="title-text text-blue"><strong>{{$t('advance.prix.RETROCESSION')}}</strong></div>
                    <div class="box-border-blue">
                        <table class="table-prix">
                            <tbody>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.InscriptionRETRO_de_UCD.items[0].value" v-model="prix.InscriptionRETRO_de_UCD.data" :disabled="disabledInput(prix.InscriptionRETRO_de_UCD_Debut.data, prix.InscriptionRETRO_de_UCD_Fin.data)" name="InscriptionRETRO_de_UCD">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.InscriptionRETRO_de_UCD.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.InscriptionRETRO_de_UCD_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.InscriptionRETRO_de_UCD_Debut.title"
                                                    :clearable="true" name="InscriptionRETRO_de_UCD_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.InscriptionRETRO_de_UCD.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.InscriptionRETRO_de_UCD_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.InscriptionRETRO_de_UCD_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.InscriptionRETRO_de_UCD_Fin.title"
                                                    :clearable="true" name="InscriptionRETRO_de_UCD_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.InscriptionRETRO_de_UCD.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.InscriptionRETRO_de_UCD_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.PrixRETRO.items[0].value" v-model="prix.PrixRETRO.data" :disabled="disabledInput(prix.PrixRETRO_Debut.data, prix.PrixRETRO_Fin.data)" name="PrixRETRO">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.PrixRETRO.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.PrixRETRO_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.PrixRETRO_Debut.title"
                                                    :clearable="true" name="PrixRETRO_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.PrixRETRO.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.PrixRETRO_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.PrixRETRO_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.PrixRETRO_Fin.title"
                                                    :clearable="true" name="PrixRETRO_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.PrixRETRO.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.PrixRETRO_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>                                                
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="border-none">
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.ApplicationPrixRetrocession.items[0].value" v-model="prix.ApplicationPrixRetrocession.data" :disabled="disabledInput(prix.ApplicationPrixRetrocession_Debut.data, prix.ApplicationPrixRetrocession_Fin.data)" name="ApplicationPrixRetrocession">
                                            <span class="check-recherche"></span>
                                             <div class="txt-check text-blue-bolder font-14">
                                                {{prix.ApplicationPrixRetrocession.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.ApplicationPrixRetrocession_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.ApplicationPrixRetrocession_Debut.title"
                                                    :clearable="true" name="ApplicationPrixRetrocession_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.ApplicationPrixRetrocession.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.ApplicationPrixRetrocession_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.ApplicationPrixRetrocession_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.ApplicationPrixRetrocession_Fin.title"
                                                    :clearable="true" name="ApplicationPrixRetrocession_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.ApplicationPrixRetrocession.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.ApplicationPrixRetrocession_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label style="padding-left: 25px;">
                                            <!--<input type="checkbox">-->
                                            <!--<span class="check-recherche"></span>-->
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.Variation_du_PrixRetrocession.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="box-check box-check-text">
                                                <label > 
                                                    <input type="checkbox" :value="prix.Variation_du_PrixRetrocession.items.Augmentation.value" v-model="prix.Variation_du_PrixRetrocession.data" name="Variation_du_PrixRetrocession">
                                                    <span class="check-recherche"></span>
                                                    <div class="txt-check text-gray">
                                                        {{prix.Variation_du_PrixRetrocession.items.Augmentation.display}}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-check box-check-text">
                                                <label> 
                                                    <input type="checkbox" :value="prix.Variation_du_PrixRetrocession.items.Baisse.value" v-model="prix.Variation_du_PrixRetrocession.data" name="Variation_du_PrixRetrocession">
                                                    <span class="check-recherche"></span>
                                                    <div class="txt-check text-gray">
                                                        {{prix.Variation_du_PrixRetrocession.items.Baisse.display}}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="box-check box-check-text">
                                        <label>
                                            <input type="checkbox" :value="prix.RadiationRETRO_de_UCD.items[0].value" v-model="prix.RadiationRETRO_de_UCD.data" :disabled="disabledInput(prix.RadiationRETRO_de_UCD_Debut.data, prix.RadiationRETRO_de_UCD_Fin.data)" name="RadiationRETRO_de_UCD">
                                            <span class="check-recherche"></span>
                                            <div class="txt-check text-blue-bolder font-14">
                                                {{prix.RadiationRETRO_de_UCD.title}} :
                                            </div>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-lg-12" style="margin-bottom: 8px;">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.RadiationRETRO_de_UCD_Debut.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.RadiationRETRO_de_UCD_Debut.title"
                                                    :clearable="true" name="RadiationRETRO_de_UCD_Debut" autocomplete="off"
                                                    :disabled="disabledInput(prix.RadiationRETRO_de_UCD.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.RadiationRETRO_de_UCD_Debut.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="box-input-circle">
                                                <datepicker 
                                                    v-model="prix.RadiationRETRO_de_UCD_Fin.data" 
                                                    :locale="language"
                                                    inputFormat="MM/yyyy" 
                                                    minimumView="month"
                                                    class="form-control" 
                                                    :placeholder="prix.RadiationRETRO_de_UCD_Fin.title"
                                                    :clearable="true" name="RadiationRETRO_de_UCD_Fin" autocomplete="off"
                                                    :disabled="disabledInput(prix.RadiationRETRO_de_UCD.data)"
                                                    >  
                                                    <template v-slot:clear>
                                                        <div class="date_clearable" @click="prix.RadiationRETRO_de_UCD_Fin.data = null"><em>x</em></div>
                                                    </template>
                                                </datepicker>

                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-sm col-lg-6">
                    <div class="mb-3">
                        <div class="title-text text-blue"><strong>{{$t('advance.prix.AGREMENT_COLLECTIVITES')}}</strong></div>
                        <div class="box-border-blue">
                            <table class="table-prix">
                                <tbody>
                                <tr>
                                    <td>
                                        <div class="box-check box-check-text">
                                            <label>
                                                <input type="checkbox" :value="prix.InscriptionCOLL_du_CIP.items[0].value" v-model="prix.InscriptionCOLL_du_CIP.data" :disabled="disabledInput(prix.InscriptionCOLL_du_CIP_Debut.data, prix.InscriptionCOLL_du_CIP_Fin.data)" name="InscriptionCOLL_du_CIP">
                                                <span class="check-recherche"></span>
                                                <div class="txt-check text-blue-bolder font-14">
                                                    {{prix.InscriptionCOLL_du_CIP.title}} :
                                                </div>
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col-lg-12" style="margin-bottom: 8px;">
                                                <div class="box-input-circle">
                                                    <datepicker 
                                                        v-model="prix.InscriptionCOLL_du_CIP_Debut.data" 
                                                        :locale="language"
                                                        inputFormat="MM/yyyy" 
                                                        minimumView="month"
                                                        class="form-control" 
                                                        :placeholder="prix.InscriptionCOLL_du_CIP_Debut.title"
                                                        :clearable="true" name="InscriptionCOLL_du_CIP_Debut" autocomplete="off"
                                                        :disabled="disabledInput(prix.InscriptionCOLL_du_CIP.data)"
                                                        >  
                                                        <template v-slot:clear>
                                                            <div class="date_clearable" @click="prix.InscriptionCOLL_du_CIP_Debut.data = null"><em>x</em></div>
                                                        </template>
                                                    </datepicker>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="box-input-circle">
                                                    <datepicker 
                                                        v-model="prix.InscriptionCOLL_du_CIP_Fin.data" 
                                                        :locale="language"
                                                        inputFormat="MM/yyyy" 
                                                        minimumView="month"
                                                        class="form-control" 
                                                        :placeholder="prix.InscriptionCOLL_du_CIP_Fin.title"
                                                        :clearable="true" name="InscriptionCOLL_du_CIP_Fin" autocomplete="off"
                                                        :disabled="disabledInput(prix.InscriptionCOLL_du_CIP.data)"
                                                        >  
                                                        <template v-slot:clear>
                                                            <div class="date_clearable" @click="prix.InscriptionCOLL_du_CIP_Fin.data = null"><em>x</em></div>
                                                        </template>
                                                    </datepicker>

                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="box-check box-check-text">
                                            <label>
                                                <input type="checkbox" :value="prix.RadiationCOLL_du_CIP.items[0].value" v-model="prix.RadiationCOLL_du_CIP.data" :disabled="disabledInput(prix.RadiationCOLL_du_CIP_Debut.data, prix.RadiationCOLL_du_CIP_Fin.data)" name="RadiationCOLL_du_CIP">
                                                <span class="check-recherche"></span>
                                                <div class="txt-check text-blue-bolder font-14">
                                                    {{prix.RadiationCOLL_du_CIP.title}} :
                                                </div>
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col-lg-12" style="margin-bottom: 8px;">
                                                <div class="box-input-circle">
                                                    <datepicker 
                                                        v-model="prix.RadiationCOLL_du_CIP_Debut.data" 
                                                        :locale="language"
                                                        inputFormat="MM/yyyy" 
                                                        minimumView="month"
                                                        class="form-control" 
                                                        :placeholder="prix.RadiationCOLL_du_CIP_Debut.title"
                                                        :clearable="true" name="RadiationCOLL_du_CIP_Debut" autocomplete="off"
                                                        :disabled="disabledInput(prix.RadiationCOLL_du_CIP.data)"
                                                        >  
                                                        <template v-slot:clear>
                                                            <div class="date_clearable" @click="prix.RadiationCOLL_du_CIP_Debut.data = null"><em>x</em></div>
                                                        </template>
                                                    </datepicker>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="box-input-circle">
                                                    <datepicker 
                                                        v-model="prix.RadiationCOLL_du_CIP_Fin.data" 
                                                        :locale="language"
                                                        inputFormat="MM/yyyy" 
                                                        minimumView="month"
                                                        class="form-control" 
                                                        :placeholder="prix.RadiationCOLL_du_CIP_Fin.title"
                                                        :clearable="true" name="RadiationCOLL_du_CIP_Fin" autocomplete="off"
                                                        :disabled="disabledInput(prix.RadiationCOLL_du_CIP.data)"
                                                        >  
                                                        <template v-slot:clear>
                                                            <div class="date_clearable" @click="prix.RadiationCOLL_du_CIP_Fin.data = null"><em>x</em></div>
                                                        </template>
                                                    </datepicker>

                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="title-text text-blue"><strong>{{$t('advance.prix.ATU_Prix')}}</strong></div>
                    <div class="mb-3">
                        <div class="box-border-blue">
                            <div class="box-check box-check-text">
                                <label>
                                    <input type="checkbox" :value="prix.ATU_Prix.items[0].value" v-model="prix.ATU_Prix.data" name="ATU_Prix">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-blue-bolder font-14">
                                        {{prix.ATU_Prix.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <template v-if="isAdmin">
                        <div class="title-text text-blue"><strong>{{prix.type_amm.title}}</strong></div>
                        <div class="box-border-blue">
                            <div v-for="(item,key) in prix.type_amm.items" :key="key" class="box-check box-check-text">
                                <label> 
                                    <input type="checkbox" :value="item.value" v-model="prix.type_amm.data">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-gray">
                                            {{item.display}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </template>

                </div>
            </div>
        </div>
        <div class="box-wrap">
            <div class="title-text text-blue"><strong>{{$t('advance.prix.GROUPE_GENERIQUE')}}</strong></div>
            <div class="box-bg-gray">
                <div class="row row-15">
                    <div class="col-lg-7 col-sm-7 col-xs-12 border-dashed-blue">
                        <table class="table-row">
                            <tbody>
                            <tr>
                                <td><strong class="text-blue">{{prix.Libelle_du_groupe_generique.title}} :</strong></td>
                                <td><autocomplete :classinputname="'form-control form-input-noborder'" :placeholder="prix.Libelle_du_groupe_generique.placeholder"   v-model="prix.Libelle_du_groupe_generique.data" :items="autocomplete_libelle" inputname="Libelle_du_groupe_generique" @onAutocomplete="onAutocomplete"  methodfor="advance" :empty="false" :clear="true"  />    </td>
                            </tr>
                            <tr>
                                <td><strong class="text-blue">{{prix.Libelle_DCI_du_groupe_generique.title}} :</strong></td>
                                <td><autocomplete :classinputname="'form-control form-input-noborder'" :placeholder="prix.Libelle_DCI_du_groupe_generique.placeholder"   v-model="prix.Libelle_DCI_du_groupe_generique.data" :items="autocomplete_libelle_dci" inputname="Libelle_DCI_du_groupe_generique" @onAutocomplete="onAutocomplete"  methodfor="advance" :empty="false"  :clear="true"  />    </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12">
                        <div class="box-padding" style="font-size: 14px;">
                            <div class="box-check box-check-text">
                                <label>
                                    <input type="checkbox" :value="prix.TFR.items[0].value" v-model="prix.TFR.data" name="TFR">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-blue-bolder font-14">
                                        {{prix.TFR.title}}
                                    </div>
                                </label>
                            </div>
                            <div class="box-check box-check-text">
                                <label>
                                    <input type="checkbox" :value="prix.Generique.items[0].value" v-model="prix.Generique.data" name="Generique">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-blue-bolder font-14">
                                        {{prix.Generique.title}}
                                    </div>
                                </label>
                            </div>
                            <div class="box-check box-check-text">
                                <label>
                                    <input type="checkbox" :value="prix.Referent.items[0].value" v-model="prix.Referent.data" name="Referent">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-blue-bolder font-14">
                                        {{prix.Referent.title}}
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-wrap">
            <div class="title-text text-blue"><strong>{{$t('advance.prix.REPERTOIRE_BIOSIMILAIRE')}}</strong></div>
            <div class="box-bg-gray">
                <div class="row row-15">
                    <div class="col-lg-7 col-md-7 col-12">
                        <table class="table-row">
                            <tbody>
                            <tr>
                                <td><strong class="text-blue">{{prix.Medicament_de_reference.title}} :</strong></td>
                                <td><autocomplete :classinputname="'form-control form-input-noborder'" :placeholder="prix.Medicament_de_reference.placeholder"   v-model="prix.Medicament_de_reference.data" :items="autocomplete_bs_medicament" inputname="Medicament_de_reference" @onAutocomplete="onAutocomplete"  methodfor="advance"  :empty="false" :clear="true"  />    </td>
                            </tr>
                            <tr>
                                <td><strong class="text-blue">{{prix.Substance_active.title}} :</strong></td>
                                <td><autocomplete :classinputname="'form-control form-input-noborder'" :placeholder="prix.Substance_active.placeholder"   v-model="prix.Substance_active.data" :items="autocomplete_bs_substance" inputname="Substance_active" @onAutocomplete="onAutocomplete"  methodfor="advance"  :empty="false" :clear="true"  />    </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-wrap">
            <div class="title-text text-blue"><strong>{{$t('advance.prix.JOURNAL_OFFICIEL')}}</strong></div>
            <div class="box-border-blue">
                <div class="row row-15">
                    <div class="col-lg-7 col-md-7 col-12">
                        <table class="table-row">
                            <tbody>
                            <tr>
                                <td><strong class="text-blue">{{prix.DateJO.title}}</strong></td>
                                <td>
                                    <div class="box-input-circle" style="width:120%;">
                                    <datepicker 
                                        v-model="prix.DateJO.data" 
                                        :locale="language"
                                        inputFormat="dd-MM-yyyy" 
                                        class="form-control datejo" 
                                        :placeholder="prix.DateJO.placeholder"
                                        :clearable="true" name="DateJO" autocomplete="off" 
                                         
                                        >  
                                        <template v-slot:clear>
                                            <div class="date_clearable" @click="prix.DateJO.data = null"><em>x</em></div>
                                        </template>
                                    </datepicker> 
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- <div style="margin-top: 5px;"><span class="text-blue">*</span> E.g.: 01-09-2014</div> -->
        </div>
    </div>

           
</template>

<script>
import Datepicker from 'vue3-datepicker'
import Autocomplete from '@/components/elements/autocomplete.vue';
import { isAdmin } from '@/utils'; 
import { enGB, fr, de } from 'date-fns/locale'
export default {
  name: 'prix',
  components: {
      Datepicker,
      Autocomplete,
  },
  data: function(){
       return {
            languages : {enGB, fr, de },
            prix : {
                'title': 'PRIX',
                InscriptionSSduCIP : {
                    title : 'Inscription SS du CIP',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                InscriptionSSduCIP_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Inscription SS Début', 
                    data : null
                },
                InscriptionSSduCIP_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Inscription SS Fin', 
                    data : null
                },
                PrixSS : {
                    title : 'Prix SS (JO)',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                PrixSS_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Prix SS (JO) Début',
                    data : null
                },
                PrixSS_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Prix SS (JO) Fin',
                    data : null
                },
                ApplicationPrixSS : {
                    title : 'Application prix SS',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                ApplicationPrixSS_Debut : {
                    title : this.$t('date_start'),
                    titleInfo : 'Application Prix SS Début', 
                    data : null
                },
                ApplicationPrixSS_Fin : {
                    title : this.$t('date_end'),
                    titleInfo : 'Application Prix SS Fin', 
                    data : null
                },
                Variation_du_prixSS : {
                    title : 'Variation du prix SS',
                    items : {
                        Augmentation : {'display' : this.$t('advance.prix.Augmentation'), 'value' : '1'},
                        Baisse : {'display' : this.$t('advance.prix.Baisse'), 'value' : '2'}
                    },
                    data : []
                },
                RadiationSS_du_CIP : {
                    title : 'Radiation SS du CIP',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                RadiationSS_du_CIP_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Radiation SS Début', 
                    data : null
                },
                RadiationSS_du_CIP_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Radiation SS Fin', 
                    data : null
                },
                InscriptionT2A_de_UCD : {
                    title : 'Inscription T2A de UCD',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                InscriptionT2A_de_UCD_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Inscription T2A Début', 
                    data : null
                },
                InscriptionT2A_de_UCD_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Inscription T2A Fin', 
                    data : null
                },
                PrixT2A : {
                    title : 'Prix T2A (JO)',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                PrixT2A_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Prix T2A (JO) Début', 
                    data : null
                },
                PrixT2A_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Prix T2A (JO) Fin', 
                    data : null
                },
                ApplicationPrixT2A : {
                    title : 'Application prix T2A',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                ApplicationPrixT2A_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Application prix T2A Début', 
                    data : null
                },
                ApplicationPrixT2A_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Application prix T2A Fin', 
                    data : null
                },
                Variation_du_prixT2A : {
                    title : 'Variation du prix T2A',
                    items : {
                        Augmentation : {'display' : this.$t('advance.prix.Augmentation'), 'value' : '1'},
                        Baisse : {'display': this.$t('advance.prix.Baisse'), 'value' : '2'}
                    },
                    data : []
                },
                RadiationT2A_de_UCD : {
                    title : 'Radiation T2A de l’UCD',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                RadiationT2A_de_UCD_Debut : {
                    title : this.$t('date_start'),
                    titleInfo : 'Radiation T2A Début',
                    data : null
                },
                RadiationT2A_de_UCD_Fin : {
                    title : this.$t('date_end'),
                    titleInfo : 'Radiation T2A Fin',
                    data : null
                },
                InscriptionRETRO_de_UCD : {
                    title : 'Inscription RETRO de UCD',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                InscriptionRETRO_de_UCD_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Inscription Rétrocession Début',
                    data : null
                },
                InscriptionRETRO_de_UCD_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Inscription Rétrocession Fin', 
                    data : null
                },
                PrixRETRO : {
                    title : 'Prix RETRO (JO)',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                PrixRETRO_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Prix RETRO (JO) Début',
                    data : null
                },
                PrixRETRO_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Prix RETRO (JO) Fin',
                    data : null
                },
                ApplicationPrixRetrocession : {
                    title : 'Application prix Rétrocession',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                ApplicationPrixRetrocession_Debut : {
                    title : this.$t('date_start'), 
                    titleInfo : 'Application prix Rétrocession Début',
                    data : null
                },
                ApplicationPrixRetrocession_Fin : {
                    title : this.$t('date_end'), 
                    titleInfo : 'Application prix Rétrocession Fin',
                    data : null
                },
                Variation_du_PrixRetrocession : {
                    title : 'Variation du prix Rétrocession',
                    items : {
                        Augmentation : {'display' : this.$t('advance.prix.Augmentation'), 'value' : '1'},
                        Baisse : {'display' : this.$t('advance.prix.Baisse'), 'value' : '2'}
                    },
                    data : []
                },
                RadiationRETRO_de_UCD : {
                    title : 'Radiation RETRO de l’UCD',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                RadiationRETRO_de_UCD_Debut : {
                    title : this.$t('date_start'),
                    titleInfo : 'Radiation Rétrocession Début',
                    data : null
                },
                RadiationRETRO_de_UCD_Fin : {
                    title : this.$t('date_end'),
                    titleInfo : 'Radiation Rétrocession Fin',
                    data : null
                },
                InscriptionCOLL_du_CIP : {
                    title : 'Inscription COLL du CIP',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                InscriptionCOLL_du_CIP_Debut : {
                    title : this.$t('date_start'),
                    titleInfo : 'Inscription COLL Début',
                    data : null
                },
                InscriptionCOLL_du_CIP_Fin : {
                    title : this.$t('date_end'),
                    titleInfo : 'Inscription COLL Fin',
                    data : null
                },
                RadiationCOLL_du_CIP : {
                    title : 'Radiation COLL du CIP',
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : []
                },
                RadiationCOLL_du_CIP_Debut : {
                    title : this.$t('date_start'),
                    titleInfo : 'Radiation COLL Début',
                    data : null
                },
                RadiationCOLL_du_CIP_Fin : {
                    title : this.$t('date_end'),
                    titleInfo : 'Radiation COLL Fin',
                    data : null
                },
                ATU_Prix : {
                    title : this.$t('advance.prix.ATU_Prix'),
                    items : [{ 'display' : this.$t('advance.prix.ATU_Prix'), 'value' : '1'}],
                    data : []
                },
                type_amm : {
                    title : this.$t('Type d\'AMM'),
                    items : [ { 'display' : this.$t('boite.type_amm.Générique'), 'value' : 'Générique'},
                                { 'display' : this.$t('boite.type_amm.Biosimilaire'), 'value' : 'Biosimilaire'},
                                { 'display' : this.$t('boite.type_amm.Hybride'), 'value' : 'Hybride'},
                    ],
                    data : [],
                    data_exclude : []
                },
                Libelle_du_groupe_generique : {
                    title : this.$t('advance.prix.Libelle_du_groupe_generique'),
                    placeholder : 'Écrire ici…',
                    data : [],
                    data_exclude : []
                },
                Libelle_DCI_du_groupe_generique : {
                    title : this.$t('advance.prix.Libelle_DCI_du_groupe_generique'),
                    placeholder : 'Écrire ici…',
                    data : [],
                    data_exclude : []
                },
                TFR : {
                    title : this.$t('advance.prix.TFR'),
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : [],
                    data_exclude : ''
                },
                Generique : {
                    title : this.$t('advance.prix.Generique'),
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : [],
                    data_exclude : ''
                },
                Referent : {
                    title : this.$t('advance.prix.Referent'),
                    items : [{ 'display' : this.$t('oui'), 'value' : '1'}],
                    data : [],
                    data_exclude : ''
                },
                Medicament_de_reference : {
                    title : this.$t('advance.prix.Medicament_de_reference'),
                    placeholder : 'Écrire ici…',
                    data : [],
                    data_exclude : []
                },
                Substance_active : {
                    title : this.$t('advance.prix.Substance_active'),
                    placeholder : 'Écrire ici…',
                    data : [],
                    data_exclude : []
                },
                DateJO : {
                    title : 'Date JO',
                    placeholder : 'JJ-MM-AAAA',
                    data : null
                },
            },
            lang : localStorage.getItem('language'),
            popup_style : {'top': '-272px !important'},
            //check_date : 'red'
      }
  },
    updated() {     
        // let val = this.prix.DateJO.data.split('-')
        // let days = new RegExp("\\d{2}", "i")
        // let month = new RegExp("\\d{2}", "i")
        // let year = new RegExp("\\d{4}", "i")

        // let endmonth = 31
        // if(val[1] in ['04','06','09','11']) 
        //     endmonth = 30
        // else if(val[1] == '02'){
        //     if (((val[2] % 4 == 0) && (val[2] % 100 != 0)) || (val[2] % 400 == 0))
        //         endmonth = 29
        //     else
        //         endmonth = 28
        // }

        // if(val[0]<=endmonth&&val[1]<=12&&val[2]<=9999&&days.test(val[0])&&month.test(val[1])&&year.test(val[2])){
        //     this.check_date = 'green'
        // }else{
        //     this.check_date = 'red'
        // }
       this.$emit("onReturn", { key: "Prix", value: this.prix });
   
    },
    mounted(){ 
        this.loadAutocomplete()    
        this.$emit("onReturn", { key: "Prix", value: this.prix });
    },   
    methods: {
        loadAutocomplete(){ 
            const keyLoadAutoComplete = []
            if(this.$store.getters['autocomplete/libelle'].length === 0){
                keyLoadAutoComplete.push('libelle')
            } 
            if(this.$store.getters['autocomplete/libelle_dci'].length === 0){
                keyLoadAutoComplete.push('libelle_dci')
            } 
            if(this.$store.getters['autocomplete/bs_medicament'].length === 0){
                keyLoadAutoComplete.push('bs_medicament')
            } 
            if(this.$store.getters['autocomplete/bs_substance'].length === 0){
                keyLoadAutoComplete.push('bs_substance')
            } 
            if(keyLoadAutoComplete.length > 0){
                this.$store.dispatch("autocomplete/loadAutocomplete", keyLoadAutoComplete)
            }
        },
        disabledInput(data1, data2){
            data1 + data2
            return false;
            // if(data2 !== undefined)
            // {
            //     if(data1 != '' || data2 != '') return true
            //     else return false
            // }
            // else{
            //     if(data1 != '') return true
            //     else return false
            //     }
            
        },
        onAutocomplete(param){  
            console.log('onAutocompleteprix');
            // console.log(param);
                this.prix[param.key].data.push(param.value);
                
        },
        
    }, 
    computed : {
        isAdmin,
        autocomplete_libelle () {  
            return this.$store.getters['autocomplete/libelle']            
        },
        autocomplete_libelle_dci () {  
            return this.$store.getters['autocomplete/libelle_dci']            
        },
        autocomplete_bs_medicament () {  
            return this.$store.getters['autocomplete/bs_medicament']            
        },
        autocomplete_bs_substance () {  
            return this.$store.getters['autocomplete/bs_substance']            
        },
        language(){
            return this.$i18n.locale !== 'en' ? this.languages[this.$i18n.locale] : this.languages[enGB]
        }
       
    }
}
</script>

<style scoped>
table.mx-table td{
    border-bottom:0;
    padding-top:0;
}
.form-control{
    padding: 6px 14px 10px 14px !important;
}
</style>
